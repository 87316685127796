/**
 * Bitmap class for representing a pure JS bitmap.
 */
class Bitmap {
  readonly _size: number
  readonly _chunks: Uint8Array

  /**
   * Creates a bitmap of the given size.
   * @param size The size (in bits) of the bitmap
   */
  constructor (size: number) {
    if (size <= 0) {
      throw new Error('Invalid bitmap size')
    }
    this._size = size
    this._chunks = new Uint8Array(Math.ceil(size / 8))
    for (let i = 0; i < this._chunks.length; i++) {
      this._chunks[i] = 0
    }
  }

  /**
   * Getter for the bitmap size.
   */
  get size (): number {
    return this._size
  }

  /**
   * Set the given bit in the map.
   * @param bit The bit number to set to 1.
   */
  set (bit: number): void {
    if (bit < 0 || bit >= this._size) {
      throw new Error(`Out of bounds: ${bit}`)
    }
    const chunkIndex = Math.floor(bit / 8)
    const bitMask = 2 ** (bit % 8)
    this._chunks[chunkIndex] |= bitMask
  }

  /**
   * Unset the given bit in the map.
   * @param bit The bit number to set to 0.
   */
  unset (bit: number): void {
    if (bit < 0 || bit >= this._size) {
      throw new Error(`Out of bounds: ${bit}`)
    }
    const chunkIndex = Math.floor(bit / 8)
    const bitMask = 255 - (2 ** (bit % 8))
    this._chunks[chunkIndex] &= bitMask
  }

  /**
   * Get grid of bits of specific size at index.
   * @param bit The bit number
   * @param size The size of the grid
   * @param totalWidth The total width of the image/bitmap
   * @returns Grid of bits
   */
   getGrid(bit: number, size: number, totalWidth: number): number[] {
    const grid = []
    const bitColumn = bit % totalWidth
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        const index = bit + (i*totalWidth) + j
        const indexColumn = index % totalWidth
        const insideMap = bitColumn <= indexColumn
        if (!this.isOutOfBounds(index) && insideMap) {
          grid.push(index)
        }
      }
    }
    return grid
  }

  /**
   * Set a grid of bits.
   * @param bit The bit number
   * @param size The size of the grid
   * @param totalWidth The total width of the image/bitmap
   */
  setGrid(bit: number, size: number, totalWidth: number) {
    this.getGrid(bit, size, totalWidth).forEach(i =>
      this.set(i)
    )
  }

  /**
   * Unset a grid of bits.
   * @param bit The bit number
   * @param size The size of the grid
   * @param totalWidth The total width of the image/bitmap
   */
  unsetGrid(bit: number, size: number, totalWidth: number) {
    this.getGrid(bit, size, totalWidth).forEach(i =>
      this.unset(i)
    )
  }

  /**
   * Toggle the value of the given bit.
   * @param bit The bit number to toggle the value of.
   */
  toggle (bit: number): void {
    if (this.isSet(bit)) {
      this.unset(bit)
    } else {
      this.set(bit)
    }
  }

  /**
   * Check whether the given bit is set.
   * @param bit The bit to check.
   * @returns True if the bit is set, false if not.
   */
  isSet (bit: number): boolean {
    if (bit < 0 || bit >= this._size) {
      throw new Error(`Out of bounds: ${bit}`)
    }
    const chunkIndex = Math.floor(bit / 8)
    const bitMask = 2 ** (bit % 8)
    return (this._chunks[chunkIndex] & bitMask) !== 0
  }

  /**
   * Unset all bits in the map.
   */
  unsetAll (): void {
    for (let i = 0; i < this._chunks.length; i++) {
      this._chunks[i] = 0
    }
  }

  /**
   * Set all bits in the map.
   */
  setAll (): void {
    for (let i = 0; i < this._chunks.length; i++) {
      this._chunks[i] = 255
    }
  }

  /**
   * Get a list of bit indices that are set.
   * @returns A list of all indices whise corresponding bit is set.
   */
  enabledIndices (): number[] {
    const result: number[] = []
    for (let i = 0; i < this._chunks.length; i++) {
      if (this._chunks[i] !== 0) {
        // Some bits are set here.
        const offset = i * 8
        for (let j = 0; j < 8; j++) {
          const mask = 2 ** j
          if (this._chunks[i] & mask) { // eslint-disable-line @typescript-eslint/strict-boolean-expressions
            result.push(offset + j)
          }
        }
      }
    }
    return result
  }

  /**
   * Check whether any bits are set
   * @returns True if no bits are set, false if one or more are
   */
  isEmpty (): boolean {
    return this.enabledIndices().length === 0
  }

  /**
   * Check whether any bits are out of bounds
   * @returns True if bit is out of bounds, false if not
   */
   isOutOfBounds (bit: number): boolean {
    return bit < 0 || bit >= this._size
  }
}

export { Bitmap }
