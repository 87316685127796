import { Configuration, PublicClientApplication } from '@azure/msal-browser'

export const msalConfig: Configuration = {
  auth: {
    clientId: 'a0b26464-d148-46b5-8920-35b3abdd5262',
    authority: 'https://login.microsoftonline.com/d30f3b0f-3583-4916-a703-1ad8ab1d1102',
    redirectUri: '/'
  }
}

export const loginRequest = {
  scopes: ['openid', 'https://sbhpai.com/Data.ReadWrite.All']
}

export const msalInstance = new PublicClientApplication(msalConfig)
