import { DataModel } from 'src/datamodel/DataModel'
import { damageCategoriesShort } from 'src/datamodel/DamageMapping'
import { gunzipSync } from 'fflate'

/**
 * A simple dummy data model that loads data from three files
 * stored in the dummydata folder.
 */
export class BlobDataModel extends DataModel {
  
  constructor (analysisId, serviceFactory) {
    super()
    this.analysisId = analysisId
    this.serviceClient = serviceFactory.getServiceClient()
    this.userServiceClient = serviceFactory.getUserServiceClient()
    this.imageServiceClient = serviceFactory.getImageServiceClient()
  }


  async getGltfModelUrl () {
    const tenantId = await this.getTenantId()
    return 'api/modelblob/analysis/' + this.analysisId + '/retexturedmesh/tenant/' + tenantId
  }

  async getOpenSfmModelUrl () {
    const tenantId = await this.getTenantId()
    return 'api/modelblob/analysis/' + this.analysisId + '/features/tenant/' + tenantId
  }

  async getLabelMapUrl (damageShort) {
    const tenantId = await this.getTenantId()
    return 'api/modelblob/analysis/' + this.analysisId + '/consensus/label/' + damageShort + '/tenant/' + tenantId
  }

  async getZipLabelMapUrl (damageShort) {
    const tenantId = await this.getTenantId()
    return 'api/modelblob/analysis/' + this.analysisId + '/consensus/ziplabel/' + damageShort + '/tenant/' + tenantId
  }

  getTenantId() {
    return this.userServiceClient.getCurrentTenantId()
  }

  /**
   * @see DataModel.gltfModel
   */
  async gltfModel () {
    const url = await this.getGltfModelUrl()
    const response = await this.serviceClient.getRequestResponse(url)
    const link = await response.text()
    const file_response = await fetch(link, {
      method: 'GET'
    })
    return file_response.blob()
  }

  /**
   * @see DataModel.labelMaps
   */
  async labelMaps () {
    const resolvedLabelmaps = {}
    for (let i = 0; i < damageCategoriesShort.length; i++) {
      const response = await this.serviceClient.getRequestResponse(await this.getZipLabelMapUrl(damageCategoriesShort[i]))
      const link = await response.text()
      const file_response = await fetch(link, {
        method: 'GET'
      })
      // Check if the labelmap was fetched from blob storage
      if (file_response.status === 200) {
        const buffer = await file_response.arrayBuffer()
        resolvedLabelmaps[damageCategoriesShort[i].toLowerCase()] = gunzipSync(new Uint8Array(buffer)).buffer
      }
    }
    return resolvedLabelmaps
  }

  /**
   * @see DataModel.opensfm
   */
  async opensfm () {
    const url = await this.getOpenSfmModelUrl()
    const response = await this.serviceClient.getRequestResponse(url)
    const link = await response.text()
    const file_response = await fetch(link, {
      method: 'GET'
    })
    const json = await file_response.json()
    return json
  }

  /**
   * TODO: Rename this method to imageByName
   * 
   * An example of an imagename:
   * 0a0cfe50-6b6f-4993-ae7f-0e881c83b859-HighQuality.jpg
   */
  async imageById (imageName) {
    const response = await this.imageServiceClient.getImage(this.analysisId, imageName)
    return this._convertBlobToBase64(response)
  }

  /**
   * Converter that accepts a blob and converts it to a base64 encoded
   * string with mime type ready for use in e.g. the src attribute of
   * an img tag.
   * @param {*} blob The blob to convert.
   * @returns A promise that resolves to a base64 encoded string with mime type.
   */
  _convertBlobToBase64 (blob) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onerror = () => {
        reject(new Error('Error in base64 conversion.'))
      }
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.readAsDataURL(blob)
    })
  }
}
