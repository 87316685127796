import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
 filters: {
  width: 220,
  backgroundColor: 'rgba(255, 255, 255, 0.55)',
  borderRadius: 4,
  position: 'absolute',
  top: theme.spacing(6) + 8,
  left: 8,
 },
 checkbox: {
  width: '100%',
 },
 opacity: {
  width: '100%',
 },
 toggle: {
  position: 'absolute',
  top: 10,
  right: 10,
 },
 labelColor: {
  width: 10, 
  height: 10, 
  float: 'left', 
  marginTop: 6, 
  marginRight: 4, 
  borderRadius: '50%',
 },
}))

export default useStyles
