import { BitmapImage, BoundingBox, ColorChannel } from "src/components/mltraininggrid/BitmapImage"
import { damageCategoriesShort, DamageCategoryShort } from "src/datamodel/DamageMapping"
import { Placement } from "src/datasource/model/Annotation"

export const calcPlacement = (overlay: ImageData, overlayScaleFactor: number, imageWidth: number, imageHeight: number): Placement => {
  const bitmapImage = BitmapImage.fromRGBAData(overlay.data, imageWidth/overlayScaleFactor, imageHeight/overlayScaleFactor, ColorChannel.Red)
  bitmapImage.scaleUp(overlayScaleFactor)
  return convertBoundingBoxToPlacement(bitmapImage.boundingBox(1), imageWidth, imageHeight)
}

/**
   * Converts a BoundingBox from a BitmapImage to a Placement
   * @param {BoundingBox} boundingBox Bounding box around damage annotation, calculated from BitmapImage
   * @param {number|null} imageWidth Image width
   * @param {number} imageHeight Image height
   * @param {ImageData} decimals Number of decimals included in result (default = 4)
   * @returns {Placement} Placement (top, left, width, and height scaled to 0;1)
   */
export const convertBoundingBoxToPlacement = (boundingBox: BoundingBox, imageWidth: number, imageHeight: number, decimals: number = 4): Placement => {
  const x1 = boundingBox.x1 / imageWidth
  const x2 = boundingBox.x2 / imageWidth
  const y1 = boundingBox.y1 / imageHeight
  const y2 = boundingBox.y2 / imageHeight
  return new Placement(
    parseFloat(y1.toFixed(decimals)), 
    parseFloat(x1.toFixed(decimals)),
    parseFloat((y2 - y1).toFixed(decimals)), 
    parseFloat((x2 - x1).toFixed(decimals))
  )
}

export const convertWgAppColorToRGBA = (color: string) => {
  return `rgba(${color
      .replace('(', '')
      .replace(')', '')
      .split(',')
      .map((c, i) => i === 3 ? c : parseFloat(c) * 255)
      .join(',')})`
}

export const convertRGBAToChannels = (rgba: string) => {
  return rgba
    .replace('rgba', '')
    .replace('(', '')
    .replace(')', '')
    .split(',')
    .map((c, i) => i === 3 ? parseFloat(c) * 255 : parseInt(c))
}

export const getDamageColor = (damageCategory: DamageCategoryShort | undefined, wgLabelColors: string[] | undefined) => {
  if (wgLabelColors !== undefined && damageCategory !== undefined) {
    const index = damageCategoriesShort.indexOf(damageCategory)
    if (index !== -1) {
      const color = wgLabelColors[index]
      return convertWgAppColorToRGBA(color)
    }
  }
  return 'rgb(255, 0, 0, 128)'
}