import { Checkbox, FormControlLabel, Box, Button, Slider, Typography, IconButton } from "@material-ui/core"
import { ChangeEvent, useEffect, useState } from "react"
import useStyles from "src/components/OverlayFilters.style"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { damageCategories, damageCategoriesShort, DamageCategoryShort } from "src/datamodel/DamageMapping"
import { useApp } from "src/components/AppContextProvider"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { getDamageColor } from "src/util/annotationUtil"
interface VisibleDamage {
  category: string
  visible: boolean
}

export const OverlayFilters = () => {
  const defaultOpacity = 50
  const classes = useStyles()
  const [visibleDamages, setVisibleDamages] = useState<VisibleDamage[]>([])
  const [opacity, setOpacity] = useState<number>(defaultOpacity)
  const [expanded, setExpanded] = useState<boolean>(true)
  const [gizmoVisible, setGizmoVisible] = useState<boolean>(false)
  const [reviewedVisible, setReviewedVisible] = useState<boolean>(true)
  const [unreviewedVisible, setUnreviewedVisible] = useState<boolean>(true)
  const { wgApp, userAnnotationIds } = useApp()
  
  useEffect(() => {
    if (wgApp !== undefined) {
      setOpacity(wgApp.getDamageOpacity() * 100)
      setVisibleDamages(wgApp.getDamageCategoryVisibility().map(category => { return { category, visible: true } }))
    }
  }, [wgApp])

  useEffect(() => {
    if (wgApp !== undefined) {
      wgApp.setDamageOpacity(opacity * 0.01)
    }
  }, [wgApp, opacity])

  useEffect(() => {
    if (wgApp !== undefined) {
      wgApp.setGizmoVisibility(gizmoVisible)
    }
  }, [wgApp, gizmoVisible])

  useEffect(() => {
    if (wgApp !== undefined) {
      if (reviewedVisible && unreviewedVisible) {
        wgApp.showAllAnnotations()
      }
      else if (!reviewedVisible && unreviewedVisible) {
        // Hide userAnnotationIds, show all others
        wgApp.hideAnnotations(userAnnotationIds.map(e => parseInt(e)))
      }
      else if (reviewedVisible && !unreviewedVisible) {
        // Show userAnnotationIds, hide all others
        wgApp.showAnnotations(userAnnotationIds.map(e => parseInt(e)))
      }
      else {
        wgApp.hideAllAnnotations()
      }
    }
  }, [wgApp, userAnnotationIds, reviewedVisible, unreviewedVisible])

  const toggleCategory = (e: ChangeEvent<HTMLInputElement>, category: string) => {
    const checked: boolean = e.target.checked
    if (wgApp !== undefined) {
      update3dModel(checked, category)
      updateUIState(checked, category)
    }
  }

  const update3dModel = (checked: boolean, category: string) => {
    if (wgApp !== undefined) {
      const cats: string[] = [...wgApp.getDamageCategoryVisibility()]
      if (!checked) {
        let i: number = cats.indexOf(category)
        cats.splice(i, 1)
      } else {
        cats.splice(cats.length, 0, category)
      }
      wgApp.setDamageCategoryVisibility(cats)
    }
  }

  const updateUIState = (checked: boolean, category: string) => {
    let iv: number = visibleDamages.findIndex(v => v.category === category)
    visibleDamages[iv].visible = checked
    setVisibleDamages([...visibleDamages])
  }

  const reset = () => {
    if (wgApp !== undefined) {
      wgApp.setDamageCategoryVisibility(damageCategoriesShort)
      setVisibleDamages(wgApp.getDamageCategoryVisibility().map(category => { return { category, visible: true } }))
      setOpacity(defaultOpacity)
      setReviewedVisible(true)
      setUnreviewedVisible(true)
      setGizmoVisible(false)
    }
  }

  const getLabel = (category: string) => {
    const index = damageCategoriesShort.indexOf(category as DamageCategoryShort)
    const color = getDamageColor(category, wgApp?.getLabelColors())

    return (
      <div>
        <div style={{ background: color }} className={classes.labelColor}></div>
        {damageCategories[index]}
      </div>
    )
  }

  return (
    <div className={classes.filters}>
      {expanded ?
        <IconButton 
          className={classes.toggle} 
          size="small"
          onClick={() => setExpanded(!expanded)}>
          <ExpandLessIcon />
        </IconButton>
        :
        <IconButton 
          className={classes.toggle} 
          size="small"
          onClick={() => setExpanded(!expanded)}>
          <ExpandMoreIcon />
        </IconButton>
      }
      {expanded ?
        <Box p={2}>
          {visibleDamages.map((d, i) => 
            <FormControlLabel
              className={classes.checkbox}
              key={i}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={visibleDamages[i].visible}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => toggleCategory(e, d.category)}
                  name="damage"
                  color="primary"
                />
              }
              label={getLabel(d.category)}
            />
            )}
          <br/>
          <Typography id="discrete-slider">
            Opacity ({opacity}%)
          </Typography>
          <Slider
            id="opacity-slider"
            className={classes.opacity}
            value={opacity}
            min={0}
            max={100}
            onChange={(event: ChangeEvent<{}>, newValue: number | number[]) => setOpacity(newValue as number)}
          />
          <FormControlLabel
            className={classes.checkbox}
            key="gizmo"
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={gizmoVisible}
                onChange={(e: ChangeEvent<{}>, newValue : boolean) => setGizmoVisible(newValue)}
                name="gizmo"
                color="primary"
              />
            }
            label="Show gizmo"
          />
          <FormControlLabel
            className={classes.checkbox}
            key="reviewed"
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={reviewedVisible}
                onChange={(e: ChangeEvent<{}>, newValue : boolean) => setReviewedVisible(newValue)}
                name="reviewed"
                color="primary"
              />
            }
            label="Show reviewed"
          />
          <FormControlLabel
            className={classes.checkbox}
            key="unreviewed"
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={unreviewedVisible}
                onChange={(e: ChangeEvent<{}>, newValue : boolean) => setUnreviewedVisible(newValue)}
                name="unreviewed"
                color="primary"
              />
            }
            label="Show unreviewed"
          />
          <Button size="small" variant="outlined" onClick={() => reset()}>Reset</Button>
        </Box>
        :
        <Box p={2}>
          {[...visibleDamages].filter(v => v.visible).length + "/4 categories"} | {opacity}%
        </Box>
      }
    </div>
)
}
