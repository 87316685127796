import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  }
}))

export default useStyles
