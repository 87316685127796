export type AnnotationAction =
{
  type: 'resetAll'
}
| {
  type: 'setSelectedDamage'
  payload: number | undefined
}
| {
  type: 'setErrorMessage'
  payload: string
}
| {
  type: 'setCustomCause'
  payload: string
}
| {
  type: 'setCauseCustom'
  payload: boolean
}
| {
  type: 'setSelectedCause'
  payload: number | undefined
}
| {
  type: 'setSelectedImage'
  payload: number
}
| {
  type: 'setPrimaryImageId'
  payload: number | undefined
}
| {
  type: 'setPrimaryImageName'
  payload: string | undefined
}
| {
  type: 'setSaving'
  payload: boolean
}
| {
  type: 'setDeleting'
  payload: boolean
}
| {
  type: 'setDamageLevel'
  payload: number
}
| {
  type: 'setDescription'
  payload: string
}

export interface State {
  selectedDamage: number | undefined
  errorMessage: string
  customCause: string
  causeCustom: boolean
  selectedCause: number | undefined
  selectedImage: number
  primaryImageId: number | undefined
  primaryImageName: string | undefined
  saving: boolean
  deleting: boolean
  damageLevel: number
  description: string
}

export const initialAnnotationState: State = {
  selectedDamage: undefined,
  errorMessage: '',
  customCause: '',
  causeCustom: false,
  selectedCause: undefined,
  selectedImage: 0,
  primaryImageId: undefined,
  primaryImageName: undefined,
  saving: false,
  deleting: false,
  damageLevel: 0,
  description: ''
}

export const annotationReducer = (state: State, action: AnnotationAction): State => {
  switch (action.type) {
    case 'resetAll':
      return { ...initialAnnotationState }
    case 'setSelectedDamage':
      return { ...state, selectedDamage: action.payload }
    case 'setErrorMessage':
      return { ...state, errorMessage: action.payload }
    case 'setCustomCause':
      return { ...state, customCause: action.payload }
    case 'setCauseCustom':
      return { ...state, causeCustom: action.payload }
    case 'setSelectedCause':
      return { ...state, selectedCause: action.payload }
    case 'setSelectedImage':
      return { ...state, selectedImage: action.payload }
    case 'setPrimaryImageId':
      return { ...state, primaryImageId: action.payload }
    case 'setPrimaryImageName':
      return { ...state, primaryImageName: action.payload }
    case 'setSaving':
      return { ...state, saving: action.payload }
    case 'setDeleting':
      return { ...state, deleting: action.payload }
    case 'setDamageLevel':
      return { ...state, damageLevel: action.payload }
    case 'setDescription':
      return { ...state, description: action.payload }
    default:
      return state
  }
}
