import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react"
import { useMsal, useAccount } from '@azure/msal-react'
import { AccountInfo, AuthenticationResult, AuthError, BrowserAuthError, InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser"
import { ServiceFactory } from "src/datasource/ServiceFactory"
import { TokenProvider } from "src/datasource/TokenProvider"
import { loginRequest } from "src/AuthConfig"
import { WgApp } from 'src/wgapp/wgApp'
import { QueryClient } from 'react-query'

export interface AppContextType {
  account: AccountInfo | null
  instance: IPublicClientApplication
  serviceFactory: ServiceFactory
  wgApp: WgApp | undefined
  setWgApp: Dispatch<SetStateAction<WgApp | undefined>>
  userAnnotationIds: string[]
  setUserAnnotationIds: Dispatch<SetStateAction<string[]>>
  totalAnnotationCount: number
  setTotalAnnotationCount: Dispatch<SetStateAction<number>>
  queryClient: QueryClient
}

interface AppContextProviderProps {
  children: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
    },
  }
})

//Tell TypeScript that during runtime the parameter will not be null or undefined to avoid checks everywhere
export const AppContext = createContext<AppContextType>(undefined!)

export const useApp = (): AppContextType => {
  return useContext(AppContext)
}

export const AppContextProvider = (props: AppContextProviderProps) => {
  const { instance, accounts } = useMsal()
  const account: AccountInfo | null = useAccount(accounts[0] !== undefined ? accounts[0] : {})
  const [wgApp, setWgApp] = useState<WgApp | undefined>(undefined)
  const [userAnnotationIds, setUserAnnotationIds] = useState<string[]>([])
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0)

  const tokenProvider: TokenProvider = {
    getToken: async () => {
      if (account !== null) {
        const authresult: AuthenticationResult = await new Promise((resolve, reject) => {
          instance.acquireTokenSilent({ account: account, scopes: loginRequest.scopes })
            .then((ar: AuthenticationResult) => resolve(ar))
            .catch((error: AuthError) => {
              if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                instance.acquireTokenRedirect({ scopes: loginRequest.scopes })
                  .catch((error: AuthError) => {
                    console.error('Auth error', error)
                    reject(error)
                  })
              }
            })
        })
        return authresult.accessToken
      }
      throw new Error('No active account')
    }
  }
  const serviceFactory: ServiceFactory = ServiceFactory.instance(tokenProvider)

  return (
    <AppContext.Provider value={{ account, instance, serviceFactory, wgApp, setWgApp, userAnnotationIds, setUserAnnotationIds, totalAnnotationCount, setTotalAnnotationCount, queryClient }}>
      {props.children}
    </AppContext.Provider>
  )
}