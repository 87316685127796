import { InspectionAnalysisReport } from "src/datasource/model/InspectionAnalysisReport";
import { ServiceClient } from "src/datasource/ServiceClient";
import { UserServiceClient } from "src/datasource/UserServiceClient";

export class InspectionServiceClient {
    private readonly client: ServiceClient
    private readonly userServiceClient: UserServiceClient

    constructor(client: ServiceClient, userServiceClient: UserServiceClient) {
        this.client = client
        this.userServiceClient = userServiceClient
    }

    public async getAllInspections(): Promise<InspectionAnalysisReport[]> {
        const tenantId = await this.tenantId()
        return this.client.getRequest('api/inspection/InspectionAnalysisReport/tenant/' + tenantId.toString())
    }

    public async getAllInspectionIds(): Promise<number[]> {
        const inspections = await this.getAllInspections()
        const inspectionIds = inspections.filter(i => i.id !== null).map(inspection => {
            return inspection.id as number
        })
        return inspectionIds
    }

    private async tenantId(): Promise<number> {
        return await this.userServiceClient.getCurrentTenantId()
    }
}