import { ConditionRating } from "src/datasource/model/ConditionRating";

export const mapToBackendRatingId = (value: number, ratings: ConditionRating[]): ConditionRating => {
    const validRatings = ratings.filter(rating => rating.value === value + "")
    const length = validRatings.length
    if (length === 1) {
        return validRatings[0];
    }
    if (length === 0) {
        throw new Error("No condition rating in the backend had the value: " + value)
    }
    throw new Error("More than one condition rating in the backend had the value: " + value)
}
