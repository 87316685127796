import { ServiceClient } from 'src/datasource/ServiceClient'

export interface UserServiceClient {
  getCurrentTenantId: () => Promise<number>
  getUserId: () => Promise<number>
}

export class PaiUserServiceClient implements UserServiceClient {
  private readonly client: ServiceClient
  private tenantId: number = -1
  private userId: number = -1

  constructor (client: ServiceClient) {
    this.client = client
  }

  public async getCurrentTenantId (): Promise<number> {
    if (this.tenantId === -1) {
      const userInfo = await this.client.getRequest('api/user/UserInfo')
      this.tenantId = userInfo.tenantId
      this.userId = userInfo.id
    }
    return this.tenantId
  }

  public async getUserId (): Promise<number> {
    if (this.userId === -1) {
      const userInfo = await this.client.getRequest('api/user/UserInfo')
      this.tenantId = userInfo.tenantId
      this.userId = userInfo.id
    }
    return this.userId
  }
}
