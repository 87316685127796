import { useQuery } from "react-query"
import { AnalysisDetails } from "src/datasource/model/analysisDetails"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Card, CardContent } from '@material-ui/core'
import { Box } from "@material-ui/core"
import { useApp } from "src/components/AppContextProvider"
import { Link, useParams } from "react-router-dom"
import useStyles from "src/components/Analyses.style"
import { paths } from "src/datamodel/Constants"
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation'
import { RouterParams } from "src/App"

export const Analyses = () => {
  const { account, serviceFactory } = useApp()
  const classes = useStyles()
  const { inspectionId } = useParams<RouterParams>()
  const inspectionIdNumber: number = inspectionId ? parseInt(inspectionId) : -1

  const analysesQuery = useQuery<AnalysisDetails[]>(['analyses', inspectionId], async () => await serviceFactory.getAnalysisServiceClient().getAnalysisForInspection(inspectionIdNumber),
    {
      enabled: account !== null && inspectionIdNumber !== -1,
      onError: () => {
        console.log('Error fetching analyses')
      }
    })

  return (
    <Box width={800} m={2}>
      <Card>
        <CardContent>
          {!analysesQuery.isSuccess || analysesQuery.isFetching ?
            'Loading analyses...'
            :
            <>
              {analysesQuery.data.length === 0 ? 'No analyses found' : 'Analyses'}
              <List dense component="nav" aria-label="analyses">
                {analysesQuery.data?.map((a, i) =>
                  <Link to={`${paths.inspections}/${inspectionId}/${a.id}`} key={i} className={classes.link}>
                    <ListItem button>
                      {serviceFactory.getAnalysisServiceClient().isAlexandraAnalysis(a) &&
                        <ThreeDRotationIcon
                          color="primary"
                          className={classes.markedItem}
                          fontSize="small"
                        />
                      }
                      <ListItemText primary={`${a.title} (${a.id}) [${a.userAnnotationsCount?.length}]`} secondary={a.description} />
                    </ListItem>
                  </Link>
                )}
              </List>
            </>
          }
        </CardContent>
      </Card>
    </Box>
  )
}