import { AppBar, Toolbar, Typography, IconButton, Box } from '@material-ui/core'
import { ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from "react-router-dom"
import { paths } from 'src/datamodel/Constants'
import useStyles from 'src/components/layout/Layout.style'
import { useApp } from 'src/components/AppContextProvider'
import { RouterParams } from 'src/App'
import { Progress } from 'src/components/layout/Progress'
import { AppMenu } from 'src/components/layout/AppMenu'

interface LayoutProps {
  margin?: number
  showProgress?: boolean
  showHighlightId?: boolean
  highlightId?: number
  children: ReactNode
}

export const Layout = ({ margin, showProgress, showHighlightId, highlightId, children }: LayoutProps) => {
  const { instance } = useApp()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { inspectionId, analysisId } = useParams<RouterParams>()
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    instance.logout()
    handleClose()
  }

  //TODO: Find a better way - maybe via passed prop?
  const isReport = window.location.href.indexOf("report") !== -1

  const renderTitle = (): ReactNode => {
    let titleNode: ReactNode = <></>
    let documentTitle = ''
    if (inspectionId === undefined && analysisId === undefined) {
      documentTitle = 'Inspections'
      titleNode = 'Inspections'
    }
    if (inspectionId !== undefined && analysisId === undefined) {
      documentTitle = 'Inspection ' + inspectionId
      titleNode = (
        <>
          <Link to={paths.inspections} className={classes.linkAppBar}>Inspections</Link>
          {' | ' + inspectionId}
        </>
      )
    }
    if (inspectionId !== undefined && analysisId !== undefined) {
      documentTitle = 'Analysis ' + analysisId
      titleNode = (
        <>
          <Link to={`${paths.inspections}/${inspectionId}`} className={classes.linkAppBar}>{'Inspection ' + inspectionId}</Link>
          {' | Analysis ' + analysisId}
        </>
      )
    }
    
    if (isReport) {
      documentTitle = 'Report'
      titleNode = 'Report (test)'
    }
    document.title = documentTitle
    return titleNode
  }

  return  (
  <>
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar variant='dense'>
        <IconButton 
          onClick={handleMenu} 
          edge="start" 
          color="inherit" 
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6'>
          {renderTitle()}
        </Typography>
          {showProgress &&
            <div className={classes.progressContainer}>
              {showHighlightId &&
                <Box mr={3}>
                  <Typography variant="body1">
                    {highlightId !== -1 ? 'Id: ' + highlightId : ''}
                  </Typography>
                </Box>
              }
              <Box>
                <Progress />
              </Box>
            </div>
          }
          <AppMenu
            anchor={anchorEl}
            open={open}
            paths={paths}
            handleClose={() => handleClose()}
            handleLogout={() => handleLogout()}
          />
      </Toolbar>
    </AppBar>
    <Box mt={margin === undefined ? 8 : margin}>
      {children}
    </Box>
  </>
  )
}