import { Box, Card, CardContent, CardHeader } from '@material-ui/core'
import useStyles from 'src/components/report/Annotation.style'
import { ReactNode } from 'react'

interface AnnotationProps {
  title: string
  subtitle: string
  mainImgSrc: string
  overviewImgSrc: string
  info: ReactNode
}

export const Annotation = (props: AnnotationProps) => {
  const classes = useStyles()

  return (
    <Box m={2} className={classes.page}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.header} 
          title={props.title}
          subheader={props.subtitle} />
        <CardContent className={classes.container}>
          <Box className={classes.main}>
            <img alt="primary" className={classes.mainImg} src={props.mainImgSrc} />           
          </Box>
          <Box className={classes.overview}>
            <img alt="overview" className={classes.overviewImg} src={props.overviewImgSrc} />
          </Box>
          <Box className={classes.info}>
            {props.info}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}