import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  model: {
    position: 'relative', 
    height: '100vh',
  },
}))

export default useStyles
