import { Menu, MenuItem } from "@material-ui/core"
import { Link } from "react-router-dom"
import useStyles from "src/components/layout/AppMenu.style"

interface AppMenuProps {
  anchor: HTMLElement | null
  open: boolean
  paths: any
  handleClose: () => void
  handleLogout: () => void
}

export const AppMenu = ({ anchor, open, paths, handleClose, handleLogout }: AppMenuProps) => {
  const classes = useStyles()

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleClose()}>
        <Link to={paths.inspections} className={classes.link}>Inspections</Link>
      </MenuItem>
      <MenuItem onClick={() => handleClose()}>
        <Link to={paths.report} className={classes.link}>Report (test)</Link>
      </MenuItem>
      <MenuItem onClick={() => handleLogout()}>
        <Link to={paths.home} className={classes.link}>Log out</Link>
      </MenuItem>
  </Menu>
  )
}