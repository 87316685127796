import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const useStyles = makeStyles(theme => ({
  imgWithoutOverlay: {
  },
  imageContainer: {
    position: 'relative'
  },
  imageControls: {
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  imageControl: {
    marginRight: theme.spacing(1)
  },
  loading: {
    display: 'flex',
    background: grey[100],
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles
