import { Annotation } from 'src/datasource/model/Annotation'
import { UserServiceClient } from 'src/datasource/UserServiceClient'
import { ServiceClient } from 'src/datasource/ServiceClient'
import { ImageAnnotationsCount } from 'src/datasource/model/ImageAnnotationCount'
import { Damage } from 'src/datasource/model/Damage'
import { Cause } from 'src/datasource/model/Cause'
import { ImageAnnotationsDetails } from 'src/datasource/model/ImageAnnotationDetails'
import { AnalysisServiceClient } from 'src/datasource/AnalysisServiceClient'
import { ConditionRating } from 'src/datasource/model/ConditionRating'

export class AnnotationServiceClient {
  private readonly client: ServiceClient
  private readonly userServiceClient: UserServiceClient
  private readonly analysisServiceClient: AnalysisServiceClient
  private imageIdMap: Map<string, number> | null = null
  private imageNameMap: Map<number, string> | null = null
  private batchToIdsCache: Map<number, number[]> = new Map()

  constructor (client: ServiceClient, userServiceClient: UserServiceClient, analysisServiceClient: AnalysisServiceClient) {
    this.client = client
    this.userServiceClient = userServiceClient
    this.analysisServiceClient = analysisServiceClient
  }

  public async queryAnnotations (analysisId: number, imageIds: number[]): Promise<ImageAnnotationsDetails[]> {
    const tenantId: number = await this.userServiceClient.getCurrentTenantId()
    return await this.client.postRequest('api/annotation/ImageAnnotationsBatch/tenant/' + tenantId.toString(), {
      analysisId: analysisId,
      imageIdList: imageIds,
      tagNameIdList: [],
      conditionRatingIdList: []
    })
  }

  public async getDamages (): Promise<Damage[]> {
    const tenantId = await this.tenantId()
    return await this.client.getRequest('api/tagname/TagNameFilter/tenant/' + tenantId.toString())
  }

  public async getConditionRatings (): Promise<ConditionRating[]> {
    const tenantId = await this.tenantId()
    return await this.client.getRequest('api/conditionrating/ConditionRatingFilter/tenant/' + tenantId.toString())
  }

  public async getCausesFor (damageId: number | undefined): Promise<Cause[]> {
    if (damageId === undefined) {
      return await new Promise(
        (resolve, reject) => {
          resolve([])
        }
      )
    } else {
      const tenantId = await this.tenantId()
      return this.client.getRequest('api/annotation/StandardCauses/tenant/' + tenantId.toString() + '/damageCategory/' + damageId.toString())
    }
  }

  // Returns an overview with info about the number of annotations for each image
  public async getUserAnnotationCountForAnalysis (analysisId: number): Promise<ImageAnnotationsCount[]> {
    const tenantId = await this.tenantId()
    return await this.client.getRequest('api/annotation/ImageAnnotationsCount/tenant/' + tenantId.toString() + '/analysis/' + analysisId.toString())
  }

  public async saveAnnotation (annotation: Annotation): Promise<number> {
    const tenantId: number = await this.tenantId()
    return await this.client.postRequest('api/annotation/SaveAnnotation/tenant/' + tenantId.toString(), annotation)
  }

  public async deleteAnnotation (annotationId: number): Promise<boolean> {
    const tenantId: number = await this.tenantId()
    return await this.client.postRequest('api/annotation/DeleteAnnotation/tenant/' + tenantId.toString() + '/annotation/' + annotationId.toString(), {})
  }

  // Map the name of the image on disk "2c02ea39-a4fe-4124-9fc6-633b6fcaa21d-High_quality.jpg" to the id of the image, 1190 
  public async mapImageNameToImageId (analysisId: number, imageName: string): Promise<number | undefined> {
    return (await this.getImageIdMap(analysisId)).get(this.toImageUuid(imageName))
  }

  // Map the  image id (1190) to the UUID part of the image name on disk (2c02ea39-a4fe-4124-9fc6-633b6fcaa21d-High_quality.jpg)
  // So 1190 => 2c02ea39-a4fe-4124-9fc6-633b6fcaa21d
  public async mapImageIdToImageName (analysisId: number, imageId: number): Promise<string | undefined> {
    const map = await this.getImageNameMap(analysisId)
    return map.get(imageId)
  }

  public async getUserAnnotationsForAnalysis (analysisId: number): Promise<Annotation[]> {
    const imageBatchId = await this.analysisServiceClient.getImageBatchIdFor(analysisId)
    const imageIds = await this.getImageIdsFrom(imageBatchId);
    const imageAnnotationDetails = await this.queryAnnotations(analysisId, imageIds)
    let annotations: Annotation[] = []
    imageAnnotationDetails.forEach(details => annotations = annotations.concat(details.annotationDetails))
    return annotations.filter((details) => details.isUserCreated)
  }

  public async getUserAnnotationsMapForAnalysis (analysisId: number): Promise<Map<string, Annotation>> {
    const userAnnotations = await this.getUserAnnotationsForAnalysis(analysisId)
    const map = new Map<string, Annotation>()
    userAnnotations.forEach(annotation => {
      const systemId = this.systemIdFrom(annotation)
      if (systemId !== "") {
        map.set(systemId, annotation)
      }
    })
    return map
  }

  // Returns the user annotations that exists on the analysis and the image name
  public async getUserAnnotationsFor (analysisId: number, imageName: string): Promise<Annotation[]> {
    const imageId = await this.mapImageNameToImageId(analysisId, imageName)
    if (imageId === undefined) {
      return []
    }
    const annotations = await this.queryAnnotations(analysisId, [imageId])
    if (annotations.length === 0) {
      return []
    }
    if (annotations.length !== 1) {
      throw new Error('Image query returned results for more than one image')
    }
    const annotation = annotations[0]
    return annotation.annotationDetails.filter((details) => details.isUserCreated)
  }

  // Returns the user annotation that may exist given the 3 parameters
  public async getUserAnnotationFor (analysisId: number, imageName: string, systemAnnotation: string): Promise<Annotation | null> {
    const userAnnotations = await this.getUserAnnotationsFor(analysisId, imageName)
    const validAnnotations = userAnnotations.filter((details) => this.systemIdFrom(details) === '' + systemAnnotation)
    if (validAnnotations.length === 0) {
      return null
    }
    if (validAnnotations.length > 1) {
      throw new Error('More than one systemAnnotation present')
    }

    return validAnnotations[0]
  }

  private async getImageNameMap (analysisId: number): Promise<Map<number, string>> {
    if (this.imageNameMap !== null) {
      return this.imageNameMap
    }
    await this.updateImageMaps(analysisId)
    
    if (this.imageNameMap === null) {
      throw Error("Image maps not updated")
    }
    return this.imageNameMap
  }

  private async getImageIdMap (analysisId: number): Promise<Map<string, number>> {
    if (this.imageIdMap !== null) {
      return this.imageIdMap
    }
    await this.updateImageMaps(analysisId)

    if (this.imageIdMap === null) {
      throw Error("Image maps not updated")
    }
    return this.imageIdMap
  }

  private async updateImageMaps(analysisId: number): Promise<void> {
    const tenantId: number = await this.tenantId()
    const imageBatchId = await this.analysisServiceClient.getImageBatchIdFor(analysisId)

    // This should be split into an ImageBatchServiceClient and typed
    const imageBatch = await this.client.getRequest('api/imageBatch/ImageBatchDetailsWithImages/tenant/' + tenantId.toString() + '/' + imageBatchId.toString())
    const images = imageBatch.images as any[]

    const idMap = new Map<string, number>()
    images.forEach(image => {
      idMap.set(this.toImageUuid(image.imageBlobName as string), image.id as number)
    })

    const nameMap = new Map<number, string>()
    images.forEach(image => {
      nameMap.set(image.id as number, this.toImageUuid(image.imageBlobName as string))
    })

    this.imageIdMap = idMap
    this.imageNameMap = nameMap
  }

  private async getImageIdsFrom(imageBatchId: number) {
    const cachedIds = this.batchToIdsCache.get(imageBatchId)
    if (cachedIds !== undefined) {
      return cachedIds
    }
    const tenantId: number = await this.tenantId()
    // TODO: This should be split into an ImageBatchServiceClient and typed
    const imageBatch = await this.client.getRequest('api/imageBatch/ImageBatchDetailsWithImages/tenant/' + tenantId.toString() + '/' + imageBatchId.toString())
    const images = imageBatch.images as any[]
    
    let imageIds: number[] = []
    images.forEach(image => {
      imageIds.push(image.id as number)
    })
    this.batchToIdsCache.set(imageBatchId, imageIds)
    return imageIds
  }

  private async tenantId (): Promise<number> {
    return await this.userServiceClient.getCurrentTenantId()
  }

  // Used for storing and lookup of image name
  private toImageUuid(imageName: string): string {
    return imageName.substring(0, 36)
  }

  private systemIdFrom (annotation: Annotation): string {
    if (annotation.comment === null) {
      return ""
    }
    const tokens = annotation.comment.split("#")
    if (tokens.length !== 2) {
      return ""
    }
    return tokens[0]
  }
}
