import { AnnotationServiceClient } from 'src/datasource/AnnotationServiceClient'
import { PaiServiceClient, ServiceClient } from 'src/datasource/ServiceClient'
import { PaiUserServiceClient, UserServiceClient } from 'src/datasource/UserServiceClient'
import { TokenProvider } from 'src/datasource/TokenProvider'
import { AnalysisServiceClient } from 'src/datasource/AnalysisServiceClient'
import { InspectionServiceClient } from 'src/datasource/InspectionServiceClient'
import { BlobImageServiceClient } from 'src/datasource/BlobImageServiceClient'
import { LabelMapServiceClient } from 'src/datasource/LabelMapServiceClient'

export class ServiceFactory {
  private static readonly _serviceFactory = new ServiceFactory()

  private readonly baseUrl: string
  private serviceClient?: ServiceClient = undefined
  private userServiceClient?: UserServiceClient = undefined
  private annotationServiceClient?: AnnotationServiceClient = undefined
  private analysisServiceClient?: AnalysisServiceClient = undefined
  private tokenProvider?: TokenProvider = undefined
  private inspectionServiceClient?: InspectionServiceClient = undefined
  private imageServiceClient?: BlobImageServiceClient = undefined
  private labelMapServiceClient?: LabelMapServiceClient = undefined

  private constructor() {
    // Read environment, with the purpose of setting the baseUrl
    // for the backend.
    const env = process.env.REACT_APP_ENV
    const baseUrl = process.env.REACT_APP_BASE_URL
    if (env === undefined) {
      throw new Error("Variable ENV is not defined")
    }
    if (baseUrl === undefined) {
      throw new Error("Variable BASE_URL is not defined")
    }
    console.log("Using environment: " + env)
    this.baseUrl = baseUrl
  }

  public setTokenProvider (tokenProvider: TokenProvider): void {
    this.tokenProvider = tokenProvider
  }

  public getAnnotationService (): AnnotationServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.annotationServiceClient === undefined) {
      this.annotationServiceClient = new AnnotationServiceClient(this.getServiceClient(), this.getUserServiceClient(), this.getAnalysisServiceClient())
    }
    return this.annotationServiceClient
  }

  public getServiceClient (): ServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.serviceClient === undefined) {
      this.serviceClient = new PaiServiceClient(this.baseUrl, this.tokenProvider)
    }
    return this.serviceClient
  }

  public getUserServiceClient (): UserServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.userServiceClient === undefined) {
      this.userServiceClient = new PaiUserServiceClient(this.getServiceClient())
    }
    return this.userServiceClient
  }

  public getAnalysisServiceClient (): AnalysisServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.analysisServiceClient === undefined) {
      this.analysisServiceClient = new AnalysisServiceClient(this.getServiceClient(), this.getUserServiceClient(), this.getInspectionServiceClient())
    }
    return this.analysisServiceClient
  }

  public getInspectionServiceClient (): InspectionServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.inspectionServiceClient === undefined) {
      this.inspectionServiceClient = new InspectionServiceClient(this.getServiceClient(), this.getUserServiceClient())
    }
    return this.inspectionServiceClient
  }

  public getImageServiceClient (): BlobImageServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.imageServiceClient === undefined) {
      this.imageServiceClient = new BlobImageServiceClient(this.getServiceClient(), this.getUserServiceClient())
    }
    return this.imageServiceClient
  }

  public getLabelMapServiceClient (): LabelMapServiceClient {
    if (this.tokenProvider === undefined) {
      throw new Error('No token provider given.')
    }
    if (this.labelMapServiceClient === undefined) {
      this.labelMapServiceClient = new LabelMapServiceClient(this.getServiceClient(), this.getUserServiceClient())
    }
    return this.labelMapServiceClient
  }

  public static instance (tokenProvider: TokenProvider | undefined): ServiceFactory {
    if (tokenProvider !== undefined) {
      ServiceFactory._serviceFactory.setTokenProvider(tokenProvider)
    }
    return ServiceFactory._serviceFactory
  }
}