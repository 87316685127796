import { v4 as uuid } from 'uuid'
import { TokenProvider } from 'src/datasource/TokenProvider'

export interface ServiceClient {
  getRequest: (path: string) => Promise<any>
  postRequest: (path: string, body: any) => Promise<any>
  postBinary: (path: string, body: any) => Promise<any>
  getRequestResponse(path: string): Promise<Response>
}

export class PaiServiceClient implements ServiceClient {
  private readonly baseUrl: string
  private readonly apiKey = '2fb55734-3c56-4442-abb9-9b0af26f8624'
  private readonly tokenProvider: TokenProvider

  constructor (baseUrl: string, tokenProvider: TokenProvider) {
    this.baseUrl = baseUrl
    this.tokenProvider = tokenProvider
  }

  public async getRequest (path: string): Promise<any> {
    return (await this.getRequestResponse(path)).json()
  }
  
  public async getRequestResponse(path: string): Promise<Response> {
    // TODO: error handling, consider creating a shared request method between get and post
    const token: string = await this.tokenProvider.getToken()
    return fetch(this.baseUrl + path, {
      method: 'GET',
      headers: {
        API_KEY: this.apiKey,
        RequestId: uuid(),
        Authorization: 'Bearer ' + token
      }
    })
  }

  public async postRequest (path: string, body: any): Promise<any> {
    // TODO: error handling
    const token: string = await this.tokenProvider.getToken()
    const response: Response = await fetch(this.baseUrl + path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json; charset=utf-8',
        API_KEY: this.apiKey,
        RequestId: uuid(),
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    })
    return await response.json()
  }

  public async postBinary (path: string, body: ArrayBuffer): Promise<boolean> {
    const token: string = await this.tokenProvider.getToken()
    
    const response: Response = await fetch(this.baseUrl + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Encoding': 'identity',
        'Content-Length': '' + body.byteLength,
        API_KEY: this.apiKey,
        RequestId: uuid(),
        Authorization: 'Bearer ' + token
      },
      body: body
    })
    
    return response.status === 201 || response.status  === 200
  }
}
