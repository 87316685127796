import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RouterParams } from 'src/App'
import { WgApp } from 'src/wgapp/wgApp'
import { useApp } from 'src/components/AppContextProvider'
import { DialogController } from 'src/components/DialogController'
import { OverlayFilters } from 'src/components/OverlayFilters'
import { Box } from '@material-ui/core'
import { BlobDataModel } from 'src/datamodel/BlobDataModel'
import { useStyles } from 'src/components/Analysis.style'
import { DamageCategoryShort } from 'src/datamodel/DamageMapping'
import { Annotation } from 'src/datasource/model/Annotation'

export interface SelectedObject {
  id: number
  isNew: boolean
  type?: DamageCategoryShort
}

interface AnalysisProps {
  updateHighlightId: (highlightId: number) => void
}

export const Analysis = ({ updateHighlightId }:  AnalysisProps) => {
  const classes = useStyles()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [selectedObject, setSelectedObject] = useState<SelectedObject | undefined>(undefined)
  const { wgApp, setWgApp, account, serviceFactory, setUserAnnotationIds, setTotalAnnotationCount } = useApp()
  const [imageData, setImageData] = useState<string[]>([])
  const [annotationDialogOpen, setAnnotationDialogOpen] = useState<boolean>(false)
  const maxImages: number = 20
  const { analysisId } = useParams<RouterParams>()
  const analysisIdNumber = useMemo(() => { return analysisId ? parseInt(analysisId) : undefined}, [analysisId])
  const [highlightId, setHighlightId] = useState(-1)

  useEffect(() => {
    if (canvasRef.current !== null) {
      canvasRef.current.oncontextmenu = (e: MouseEvent) => e.preventDefault()
      const fetchUserAnnotationIds = async () => {
        if (analysisIdNumber !== undefined) {
          const data: Map<string, Annotation> = await serviceFactory.getAnnotationService().getUserAnnotationsMapForAnalysis(analysisIdNumber)
          setUserAnnotationIds(Array.from(data.keys()))
        }
      }
      fetchUserAnnotationIds()

      const wgAppLocal = new WgApp(canvasRef.current)
      const dataModel = new BlobDataModel(analysisIdNumber, serviceFactory)
      //const dataModel = new VmDummyDataModel(analysisId)
      setSelectedObject(undefined)

      setTotalAnnotationCount(0)
      wgAppLocal.setDataModel(dataModel, () => {
        setTotalAnnotationCount(wgAppLocal.getTotalAnnotationCount())
      })

      wgAppLocal.setLeftClickHandler((clickedObj: any) => {
        setImageData([])
        if (clickedObj.id === -1) {
          setSelectedObject(undefined)
        } else {
          const selectedObject: SelectedObject = clickedObj
          setSelectedObject({ ...selectedObject, isNew: false })
          const images: string[] = wgAppLocal.getAnnotationImages(clickedObj.id, clickedObj.type)
          if (images !== undefined && images.length > 0) {
            setImageData(images.slice(0, maxImages))
            setAnnotationDialogOpen(true)
          } else {
            console.error('No images found')
          }
        }
      })

      //TODO: Move content to alternative click
      wgAppLocal.setRightClickHandler((clickedObj: any) => {
        setImageData([])
        if (clickedObj.id === -1) {
          const selectedObject: SelectedObject = clickedObj
          const images = wgAppLocal.getImagesForNewAnnotation()
          if (images !== undefined && images.length > 0) {
            setImageData(images.slice(0, maxImages))
            setSelectedObject({ ...selectedObject, isNew: true })
            setAnnotationDialogOpen(true)
          } else {
            console.error('No images found')
          }
        }
      })
      
      wgAppLocal.setDamageHoverCallback((highlightedObj: any) => setHighlightId(highlightedObj.id))

      setWgApp(wgAppLocal)
    }
  }, [account, analysisIdNumber, serviceFactory, setUserAnnotationIds, setWgApp, setTotalAnnotationCount])

  useEffect(() => {
    updateHighlightId(highlightId)
  }, [highlightId, updateHighlightId])

  useEffect(() => {
    const resizeCanvas= () => {
      if (wgApp !== undefined) {
        wgApp.resize(window.innerWidth, window.innerHeight)
      }
    }
    window.addEventListener('resize', resizeCanvas, false)
    resizeCanvas()
    return () => window.removeEventListener('resize', resizeCanvas, false)
  }, [wgApp])

  return (
    <>
      {analysisIdNumber !== undefined ?
        <>
          <div className={classes.model}>
            <canvas ref={canvasRef} />
            <OverlayFilters />
          </div>
          {selectedObject !== undefined &&
            <DialogController
              onSetAnnotationDialogOpen={(open: boolean) => setAnnotationDialogOpen(open)}
              onUpdateSelectedObject={(selectedObject: SelectedObject) => setSelectedObject(selectedObject)}
              annotationDialogOpen={annotationDialogOpen}
              images={imageData}
              selectedObject={selectedObject}
              analysisId={analysisIdNumber}
            />
          }
        </>
        :
        <Box m={1}>Annotation id not found</Box>
      }
    </>
  )
}
