import { Typography } from '@material-ui/core'
import { useApp } from 'src/components/AppContextProvider'

export const Progress = () => {
  const { userAnnotationIds, totalAnnotationCount } = useApp()

  return (
    <Typography variant='body1'>
      Progress: {userAnnotationIds.length + '/' + totalAnnotationCount}
    </Typography>
  )
}
