import { BrowserAuthError } from '@azure/msal-browser'
import { MsalAuthenticationResult } from '@azure/msal-react'
import { FC, ReactElement } from 'react'
import { Box } from '@material-ui/core'

const authError: FC<MsalAuthenticationResult> = (props: MsalAuthenticationResult): ReactElement => {
  if (props.error instanceof BrowserAuthError) {
    return (
      <Box m={1}>Login failed: {props.error.errorMessage}</Box>
    )
  } else {
    console.error(props.error)
    return (
      <p>An unkonwn error Occurred, check log for details</p>
    )
  }
}

export { authError }
