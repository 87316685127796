import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

interface AlertDialogProps {
  open: boolean
  onSetOpen: (open: boolean) => void
  onYes: () => void
  onNo?: () => void
  title: string
  body: string
  yesLabel: string
  noLabel: string
}

export const AlertDialog = (props: AlertDialogProps) => {
  const { open, onSetOpen, onYes, onNo, title, body, yesLabel, noLabel } = props

  const handleClose = () => {
    onSetOpen(false)
  }

  const handleYes = () => {
    onYes()
    onSetOpen(false)
  }

  const handleNo = () => {
    if (onNo !== undefined) {
      onNo()
    }
    onSetOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={title}
        aria-describedby={body}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color="primary">{noLabel}</Button>
          <Button onClick={handleYes} color="primary" autoFocus>{yesLabel}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}