import { SelectedObject } from 'src/components/Analysis'
import { FC, useReducer, useState } from 'react'
import { AnnotationDialog, commonQueryId } from 'src/components/AnnotationDialog'
import { EditImageDialog } from 'src/components/EditImageDialog'
import { DamageCategoryShort, mapFromModelNameToBackendId } from 'src/datamodel/DamageMapping'
import { useApp } from 'src/components/AppContextProvider'
import { annotationReducer, initialAnnotationState } from 'src/reducers/annotationReducer'
import { useMutation, useQuery } from 'react-query'
import { Damage } from 'src/datasource/model/Damage'
import { Annotation } from 'src/datasource/model/Annotation'
import { Cause } from 'src/datasource/model/Cause'
import { ConditionRating } from 'src/datasource/model/ConditionRating'

interface DialogControllerProps {
  analysisId: number
  selectedObject: SelectedObject
  images: string[]
  annotationDialogOpen: boolean
  onSetAnnotationDialogOpen: (open: boolean) => void
  onUpdateSelectedObject: (selectedObject: SelectedObject) => void
}

export enum AnnotationType {
  System,
  New,
  User,
  Unknown,
}

export const DialogController: FC<DialogControllerProps> = ({ 
  selectedObject,
  images,
  annotationDialogOpen,
  analysisId,
  onUpdateSelectedObject,
  onSetAnnotationDialogOpen 
}: DialogControllerProps) => {
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const { queryClient, serviceFactory, account, wgApp, setUserAnnotationIds, setTotalAnnotationCount } = useApp()
  
  // State for the edit image dialog
  const [editImageName, setEditImageName] = useState<string>('')
  const [editAnalysisId, setEditAnalysisId] = useState<number>(-1)
  const [editAnnotationId, setEditAnnotationId] = useState<number>(-1)
  const [editDamageCategory, setEditDamageCategory] = useState<string>('')
  const [editAnnotationType, setEditAnnotationType] = useState<AnnotationType>(AnnotationType.Unknown)
  const setEditImageState = (analysisId: number, imageName: string, annotationId: number, damageCategory: string, annotationType: AnnotationType): void => {
    setEditAnalysisId(analysisId)
    setEditImageName(imageName)
    setEditAnnotationId(annotationId)
    setEditDamageCategory(damageCategory)
    setEditAnnotationType(annotationType)
  }

  // State for annotation dialog
  const [state, dispatch] = useReducer(annotationReducer, initialAnnotationState)
  const [annotationType, setAnnotationType] = useState<AnnotationType>(AnnotationType.Unknown)
  
  const revokeImageUrls = () => {
    imageQuery.data?.forEach(url => URL.revokeObjectURL(url))
    if (selectedImageQuery.data !== undefined) {
      URL.revokeObjectURL(selectedImageQuery?.data)
    }
  }

  const resetStateAndQueries = () => {
    revokeImageUrls()
    queryClient.invalidateQueries(commonQueryId)
    dispatch({ type: 'resetAll' })
  }

  const userIdQuery = useQuery<number>(['userId', commonQueryId], async () => await serviceFactory.getUserServiceClient().getUserId(), { enabled: account !== null })
  const tenantIdQuery = useQuery<number>(['tenantId', commonQueryId], async () => await serviceFactory.getUserServiceClient().getCurrentTenantId(), { enabled: account !== null })

  const damagesQuery = useQuery<Damage[]>(['damages', commonQueryId], async () => await serviceFactory.getAnnotationService().getDamages(),
    {
      enabled: account !== null,
      onError: (e) => console.error('Error fetching damages', e)
    })

  const conditionRatingsQuery = useQuery<ConditionRating[]>(['conditionratings', commonQueryId], async () => await serviceFactory.getAnnotationService().getConditionRatings(), { enabled: account !== null})
    
  const primaryImageQuery = useQuery<number | undefined>(['primaryImageId', commonQueryId, state.primaryImageName], 
    async () => await serviceFactory.getAnnotationService().mapImageNameToImageId(analysisId, state.primaryImageName!),
    {
      enabled: (annotationDialogOpen || editDialogOpen) && state.primaryImageName !== undefined,
      onSuccess: (data: number | undefined) => {
        dispatch({ type: 'setPrimaryImageId', payload: data })
      },
      onError: (e) => console.error('Error fetching image id', e)
    })
  
  const annotationQuery = useQuery<Annotation | undefined>(['annotation', commonQueryId],
    async () => { 
      const data: Map<string, Annotation> = await serviceFactory.getAnnotationService().getUserAnnotationsMapForAnalysis(analysisId)
      const fetchedIds = Array.from(data.keys())
      if (fetchedIds.length > 0) {
        setUserAnnotationIds(fetchedIds)
      }
      return data.get('' + selectedObject.id)
    },
    {
      enabled: (annotationDialogOpen || editDialogOpen) && selectedObject.id !== undefined && account !== null && damagesQuery.isSuccess,
      onSuccess: (data: Annotation | undefined) => {
        setTotalAnnotationCount(wgApp !== undefined ? wgApp.getTotalAnnotationCount() : 0)
        if (data === undefined) {
          if (selectedObject.isNew) {
            //New unedited annotation
            setAnnotationType(AnnotationType.New)
          } else {
            //System annotation or new annotation that has been edited
            setAnnotationType(AnnotationType.System)
            if (selectedObject.type !== undefined && damagesQuery.data !== undefined) {
              dispatch({ type: 'setSelectedDamage', payload: mapFromModelNameToBackendId(damagesQuery.data, selectedObject.type) })
            }
          }
        } else {
          //User annotation
          setAnnotationType(AnnotationType.User)
          if (data.customCause !== null && data.customCause !== '') {
            dispatch({ type: 'setCauseCustom', payload: true })
            dispatch({ type: 'setCustomCause', payload: data.customCause })
          }
          dispatch({ type: 'setSelectedDamage', payload: data.damageCategoryId })
          dispatch({ type: 'setSelectedCause', payload: data.standardCauseId !== null ? data.standardCauseId : undefined })
          dispatch({ type: 'setDamageLevel', payload: data.damageLevel })
          dispatch({ type: 'setDescription', payload: (data.comment !== null ? data.comment : `${selectedObject.id}#`).split('#')[1] })
          dispatch({ type: 'setPrimaryImageId', payload: data.imageId })
        }
      },
      onError: (e) => console.error('Error fetching annotation', e),
    })

  const primaryImageNameQuery = useQuery<string | undefined>(['primaryImageName', commonQueryId, state.primaryImageId], 
    async () => await serviceFactory.getAnnotationService().mapImageIdToImageName(analysisId, state.primaryImageId!),
    {
      enabled: (annotationDialogOpen || editDialogOpen) && annotationQuery.isSuccess,
      onSuccess: (data: string | undefined) => {
        dispatch({ type: 'setPrimaryImageName', payload: data })
      },
      onError: (e) => console.error('Error fetching image name', e)
    })

  const imageQuery = useQuery<string[]>(['image', commonQueryId], async () => await serviceFactory
    .getImageServiceClient()
    .getImagesAsObjectUrls(analysisId, serviceFactory.getImageServiceClient().convertImageNamesTo250px(images)), {
      enabled: (annotationDialogOpen || editDialogOpen) && primaryImageNameQuery.isSuccess && images.length > 0,
      onError: (e) => console.error('Error fetching images', e)
    })

  const selectedImageQuery = useQuery<string>(['selectedImage', commonQueryId, state.selectedImage], async () => await serviceFactory.getImageServiceClient().getImageAsObjectUrl(analysisId, images[state.selectedImage]),
    {
      enabled: (annotationDialogOpen || editDialogOpen) && images.length > 0,
      onError: (e) => console.error('Error fetching selected image', e)
    })

  const causesQuery = useQuery<Cause[]>(['causes', commonQueryId, state.selectedDamage], async () => await serviceFactory.getAnnotationService().getCausesFor(state.selectedDamage),
    {
      enabled: state.selectedDamage !== undefined,
      onError: (e) => console.error('Error fetching causes', e)
    })

  const saveMutation = useMutation(async (annotation: Annotation) => await serviceFactory.getAnnotationService().saveAnnotation(annotation),
    {
      onError: (e) => console.error('Error saving annotation', e)
    })

  const deleteMutation = useMutation(async (annotationId: number) => await serviceFactory.getAnnotationService().deleteAnnotation(annotationId))

  return (
    <>
      <AnnotationDialog
        queries={{
          userIdQuery,
          tenantIdQuery,
          damagesQuery,
          conditionRatingsQuery,
          primaryImageQuery,
          annotationQuery,
          primaryImageNameQuery,
          imageQuery,
          selectedImageQuery,
          causesQuery,
          saveMutation,
          deleteMutation,
        }}
        annotationType={annotationType}
        state={state}
        dispatch={dispatch}
        analysisId={analysisId}
        open={annotationDialogOpen}
        onDialogClose={() => {
          onSetAnnotationDialogOpen(false)
          resetStateAndQueries()
        }}
        onEditImage={(analysisId: number, imageName: string, annotationId: number, damageCategory: DamageCategoryShort, annotationType: AnnotationType): void => {
          setEditImageState(analysisId, imageName, annotationId, damageCategory, annotationType)
          setEditDialogOpen(true)
          onSetAnnotationDialogOpen(false)
        }}
        selectedObject={selectedObject}
        images={images}
      />
      <EditImageDialog
        onClose={() => {
          setEditDialogOpen(false)
          resetStateAndQueries()
        }}
        onCancel={() => {
          onSetAnnotationDialogOpen(true)
          setEditDialogOpen(false)
        }}
        onSaved={(selectedObject: SelectedObject, damageCategory: DamageCategoryShort, newId?: number) => {
          if (newId !== undefined) {
            onUpdateSelectedObject({ ...selectedObject, id: newId, isNew: false, type: damageCategory })
          }
          onSetAnnotationDialogOpen(true)
          setEditDialogOpen(false)
        }}
        selectedObject={selectedObject}
        open={editDialogOpen}
        title='Edit'
        imageName={editImageName}
        annotationId={editAnnotationId}
        damageCategory={editDamageCategory}
        analysisId={editAnalysisId}
        annotationType={editAnnotationType}
      />
    </>
  )
}
