export class WgAppInterface {
  /**
   * Set left click handler.
   * @param {function} callback The callback function to be invoked on left
   * clicks. This method must be given an object with the id of the polygon
   * clicked (set to -1 if no polygon is clicked) and the type of damage
   * the polygon represents according to the ML model.
   */
  setLeftClickHandler (callback) {
    throw Error('Not implemented.')
  }

  /**
   * Set right click handler.
   * @param {function} callback The callback function to be invoked on right
   * clicks. This method must be given an object with the id of the polygon
   * clicked (-1 if no polygon is clicked).
   */
  setRightClickHandler (callback) {
    throw Error('Not implemented.')
  }

  /**
   * Set the data model in use.
   * @param {DataModel} dataModel The data model to load the 3D model from.
   * @see DataModel
   */
  setDataModel (dataModel) {
    throw Error('Not implemented.')
  }

  /**
   * Get a list of images that have contributed points to the given polygon.
   * @param {number} polygonId The ID of the polygon.
   * @returns {list} A list of images (IDs or file names) that contain parts
   * of this polygon.
   */
  imagesInPolygon (polygonId) {
    throw Error('Not implemented')
  }

  /**
   * @param {number} annotationId The ID of the annotation to create overlay for
   * @param {string} imageId The ID or file name of the image to create overlay for
   * @param {string} damageCategoryString Short-string of a damage category
   * @returns {ImageData} Instance of RGBA pixelvalues of the overlay
   */
  getAnnotationOverlay (annotationId, imageId, damageCategoryString) {
    throw Error('Not implemented')
  }

  /**
   * Get an imagedata overlay
   * @param {string} imageName The file name of the image to create overlay for
   * @param {string} damageCategoryString Short-string of a damage category
   * @returns {ImageData} RGBA pixelvalues of the overlay
   */
  getDamageCategoryOverlay (imageName, damageCategoryString) {
    throw Error('Not implemented')
  }

  /**
   * Update an annotation from a mask drawn on top of an image
   * @param {string} imageName The file name of the image
   * @param {number} annotationId Id of the annotation to update
   * @param {string} damageCategoryString Short-string of a damage category
   * @param {ImageData} overlayData Instance of ImageData that contains the mask image
   */
  updateAnnotationInImage (imageName, annotationId, damageCategoryString, overlayData) {
    throw Error('Not implemented')
  }

  /**
   * Create an annotation from a mask drawn on top of an image
   * @param {string} imageName The file name of the image
   * @param {string} damageCategoryString Short-string of a damage category
   * @param {ImageData} overlayData Instance of ImageData that contains the mask image
   * @return {number} The Id of the new annotation
   */
  createAnnotation (imageName, damageCategoryString, overlayData) {
    throw Error('Not implemented')
  }

  /**
   * Set visible damage categories
   * @param {string[]} visibleCategories Short-strings of the damage categories to show
   */
  setDamageCategoryVisibility (visibleCategories) {
    throw Error('Not implemented')
  }

  /**
   * Hide a specific set of annotations
   * Overrides all previous calls to showAnnotations and hideAnnotations.
   * @param {number[]} annotationIds Array of ids to hide. Entries must be in [0,65535]
   */
  hideAnnotations (annotationIds) {
    throw Error('Not implemented')
  }

  /**
   * Hide all annotations
   */
  hideAllAnnotations () {
    throw Error('Not implemented')
  }

  /**
   * Show only a specific set of annotations.
   * Overrides all previous call to showAnnotations and hideAnnotations.
   * @param {number[]} annotationIds Array of ids to show. Entries must be in [0,65535]
   */
  showAnnotations (annotationIds) {
    throw Error('Not implemented')
  }

  /**
   * Show all annotations
   */
  showAllAnnotations () {
    throw Error('Not implemented')
  }

  /**
   * Delete an annotation
   * @param {number} annotationId Id of the annotation to delete
   * @param {string} damageCategoryString Short-string of a damage category
   */
  deleteAnnotation (annotationId, damageCategoryString) {
    throw Error('Not implemented')
  }

  /**
   * Get annotation IDs
   * @return {Array} Array of structs that contain damage category name and the corresponding list of annotation IDs
   */
  getAnnotationIds () {
    throw Error('Not implemented')
  }

  /*
   * Get total number of annotations for all damage categories
   * @return {number} Annotation count
   */
  getTotalAnnotationCount () {
    throw Error('Not implemented')
  }
}
