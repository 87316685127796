import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  page: {
    display: 'flex',
    width: 1000,
    '@media print': {
      margin: 0,
      pageBreakAfter: 'always',
      width: '210mm',
      height: '260mm',
    },
  },
  card: {
    '@media print': {
      boxShadow: 'none',
    },
  },
  header: {
    padding: theme.spacing(1),
  },
  container: {
    padding: 0,
  },
  main: {
    width: '100%',
    margin: theme.spacing(1),
  },
  overview: {
    width: '100%',
    margin: theme.spacing(1),
  },
  info: {
    width: '100%',
    margin: theme.spacing(1),
  },
  mainImg: {
    width: 600,
    '@media print': {
      maxHeight: '140mm',
    },
  },
  overviewImg: {
    width: 400,
  },
}))

export default useStyles
