import { Damage } from 'src/datasource/model/Damage'

export const damageCategories = ['Rust', 'Crack', 'Crack with precipitation', 'Spalling', 'Algae']
export const damageCategoriesShort = ['RUST', 'CRAC', 'CRPR', 'SPAL', 'ALGA']
export type DamageCategory = typeof damageCategories[number]
export type DamageCategoryShort = typeof damageCategoriesShort[number]
export class DamageMappingError extends Error {}

/**
 * Take the id of the current damage and find the corresponding Damage from all damages.
 * With that Damage look at the name and map it to a DamageCategoryShort.
 * If anything goes wrong, throw exceptions.
 * 
 * Ex:
 *  If the selected damage is 5, go through the damages and find damage with id = 5, and take 
 *  the value, could be "Rust".
 *  Map "Rust" to the best DamageCategoryShort it can find, in this case RUST.
 * 
 * @param damages The list of all damages that exist in the system for the given tenant
 * @param id The id of current damage
 * @returns The model name of the selected damage
 */
export const mapFromBackendIdToModelName = (damages: Damage[], id: number): string => {
    const damagesWithCorrectId = damages.filter(d => id === d.id)
    if (damagesWithCorrectId.length === 0) {
        throw new DamageMappingError("No damage had the given id: " + id + ", the damages in the system are: " + damages)
    }
    if (damagesWithCorrectId.length > 1) {
        throw new DamageMappingError("More than one damage had the given id: " + id + ", the damages in the system are: " + damages)
    }
    return backendNameToModelName(damagesWithCorrectId[0].value)
}

function backendNameToModelName(damageName: string): DamageCategoryShort {
    const damageNameLowerCase = damageName.toLowerCase().trim()
    if (damageNameLowerCase === "rust") {
        return damageCategoriesShort[0]
    }
    if (damageNameLowerCase === "crack") {
        return damageCategoriesShort[1]
    }
    if (damageNameLowerCase.includes("crack") &&
        damageNameLowerCase.includes("precipitation")) {
        return damageCategoriesShort[2]
    }
    if (damageNameLowerCase === "spalling") {
        return damageCategoriesShort[3]
    }
    if (damageNameLowerCase === "algae") {
        return damageCategoriesShort[4]
    }
    throw new DamageMappingError("No damage mapping exists for model name: " + damageName)
}

/**
 * Convert the modelName of the damage into a DamageCategory, then find the damage from
 * all the damages in the system and return its id.
 * 
 * Ex:
 *  The "CRPR" backend name is converted into 'Crack with precipitation' and that damage is found
 *  in the list of all damages and the id hereof is returned
 * 
 * @param damages The list of all damages that exist in the system for the given tenant
 * @param modelName The name of the damage in the model
 * @returns The id of the damage in the backend
 */
export const mapFromModelNameToBackendId = (damages: Damage[], modelName: DamageCategoryShort): number => {
    const backendName = modelNameToBackendName(modelName)
    const damagesWithCorrectValue = damages.filter(d => backendName.toLowerCase() === d.value.toLowerCase())
    if (damagesWithCorrectValue.length === 0) {
        throw new DamageMappingError("No damage had the given name: " + backendName + ", the damages in the system are: " + damages)
    }
    if (damagesWithCorrectValue.length > 1) {
        throw new DamageMappingError("More than one damage had the given name: " + backendName + ", the damages in the system are: " + damages)
    }
    return damagesWithCorrectValue[0].id
}

function modelNameToBackendName(damageName: DamageCategoryShort): DamageCategory {
    if (damageName === "RUST") {
        return damageCategories[0]
    }
    if (damageName === "CRAC") {
        return damageCategories[1]
    }
    if (damageName === "CRPR") {
        return damageCategories[2]
    }
    if (damageName === "SPAL") {
        return damageCategories[3]
    }
    if (damageName === "ALGA") {
        return damageCategories[4]
    }
    throw new DamageMappingError("No damage mapping exists for backend name: " + damageName)
}