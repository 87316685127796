import { Typography } from "@material-ui/core"

interface InfoProps {
  label: string
  value: string
}

export const Info = (props: InfoProps) => {
  return (
    <div>
      <Typography variant="body2">
        <b>{props.label}:</b> {props.value}
      </Typography>
    </div>
  )
}