import { ChangeEvent, Dispatch, FC, ReactNode, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slider from '@material-ui/core/Slider'
import { Box, CircularProgress, FormControlLabel, Switch, Typography } from '@material-ui/core'
import useStyles from 'src/components/AnnotationDialog.style'
import { SelectedImage } from 'src/components/SelectedImage'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { Cause } from 'src/datasource/model/Cause'
import { Damage } from 'src/datasource/model/Damage'
import { Annotation, AnnotationBuilder, Placement } from 'src/datasource/model/Annotation'
import { CustomDialog } from 'src/components/CustomDialog'
import { Thumbnail } from 'src/components/Thumbnail'
import { LabelCategorySelect } from 'src/components/LabelCategorySelect'
import { AnnotationAction, State } from 'src/reducers/annotationReducer'
import { useApp } from 'src/components/AppContextProvider'
import { DamageCategoryShort, DamageMappingError, mapFromBackendIdToModelName } from 'src/datamodel/DamageMapping'
import { SelectedObject } from 'src/components/Analysis'
import { overlayScaleFactor } from 'src/components/EditImageDialog'
import { calcPlacement, getDamageColor } from 'src/util/annotationUtil'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { AnnotationType } from 'src/components/DialogController'
import { AlertDialog } from 'src/components/AlertDialog'
import { ConditionRating } from 'src/datasource/model/ConditionRating'
import { mapToBackendRatingId } from 'src/datamodel/ConditionMapping'

interface AnnotationDialogProps {
  queries: AnnotationDialogQueries
  annotationType: AnnotationType
  state: State
  dispatch: Dispatch<AnnotationAction>
  analysisId: number
  open: boolean
  images: string[]
  selectedObject: SelectedObject
  onDialogClose: () => void
  onEditImage: (analysisId: number, imageName: string, annotationId: number, damageCategory: string, annotationType: AnnotationType) => void
}

interface AnnotationDialogQueries {
  userIdQuery: UseQueryResult<number>
  tenantIdQuery: UseQueryResult<number>
  damagesQuery: UseQueryResult<Damage[]>
  conditionRatingsQuery: UseQueryResult<ConditionRating[]>
  primaryImageQuery: UseQueryResult<number | undefined>
  annotationQuery: UseQueryResult<Annotation | undefined>
  primaryImageNameQuery: UseQueryResult<string | undefined>
  imageQuery: UseQueryResult<string[]>
  selectedImageQuery: UseQueryResult<string>
  causesQuery: UseQueryResult<Cause[]>
  saveMutation: UseMutationResult<number, unknown, Annotation, unknown>
  deleteMutation: UseMutationResult<boolean, unknown, number, unknown>
}

export const commonQueryId = 'annotationDialog'

const AnnotationDialog: FC<AnnotationDialogProps> = ({
  queries,
  annotationType,
  state,
  dispatch,
  analysisId,
  open,
  onDialogClose,
  onEditImage,
  images,
  selectedObject
}: AnnotationDialogProps) => {
  const classes = useStyles()
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false)

  const { wgApp, serviceFactory, setTotalAnnotationCount } = useApp()

  const onDamageCategorySelect = (selected: number): void => {
    dispatch({ type: 'setSelectedCause', payload: undefined })
    const damage: Damage | undefined = queries.damagesQuery.data !== undefined ? queries.damagesQuery.data[selected] : undefined
    dispatch({ type: 'setSelectedDamage', payload: damage?.id })
  }

  const onDamageCauseSelect = (selected: number): void => {
    dispatch({ type: 'setCustomCause', payload: '' })
    const cause: Cause | undefined = queries.causesQuery.data !== undefined ? queries.causesQuery.data[selected] : undefined
    dispatch({ type: 'setSelectedCause', payload: cause?.id })
  }

  const onSetDamageLevel = (event: ChangeEvent<{}>, newValue: number | number[]): void => {
    dispatch({ type: 'setDamageLevel', payload: sliderMarks.findIndex(s => s.value === newValue as number) })
  }

  const onSetAsPrimary = (imageName: string): void => {
    dispatch({ type: 'setPrimaryImageName', payload: imageName })
  }

  const handleDialogClosed = (): void => onDialogClose()

  const isReady = (): boolean => {
    return open && queries.damagesQuery.isSuccess && queries.conditionRatingsQuery.isSuccess && queries.imageQuery.isSuccess && queries.annotationQuery.isSuccess && !queries.annotationQuery.isFetching
  }

  const onSaveClicked = async (): Promise<void> => {
    const damage: Damage | undefined = queries.damagesQuery.data?.find(d => d.id === state.selectedDamage)
    if (damage !== undefined && state.description !== '' && queries.userIdQuery.isSuccess && queries.conditionRatingsQuery.isSuccess && queries.tenantIdQuery.isSuccess && state.primaryImageId !== undefined && selectedObject.id !== -1) {
      dispatch({ type: 'setErrorMessage', payload: '' })
      const conditionRating: ConditionRating = mapToBackendRatingId(state.damageLevel, queries.conditionRatingsQuery.data)
      const builder = new AnnotationBuilder(selectedObject.id.toString(), analysisId, state.primaryImageId, state.damageLevel, conditionRating.id, queries.userIdQuery.data, queries.tenantIdQuery.data, getPlacement(), damage, state.description)
        .setCauses(state.causeCustom, queries.causesQuery.data?.find(c => c.id === state.selectedCause), state.customCause)
      buildAndSaveAnnotation(builder)
    } else {
      const errorMsg = state.primaryImageId === undefined ? 'Missing primary image' :
        (selectedObject.id === -1 ? 'Missing damage drawing' : 'Missing input')
      dispatch({ type: 'setErrorMessage', payload: errorMsg })
    }
  }

  const buildAndSaveAnnotation = (builder: AnnotationBuilder) => {
    let newAnnotation: Annotation | undefined = buildNewAnnotation(builder)
    if (newAnnotation !== undefined) {
      dispatch({ type: 'setSaving', payload: true })
      saveAnnotation(newAnnotation)
    } else {
      dispatch({ type: 'setErrorMessage', payload: 'Annotation cannot be saved' })
    }
  }

  const buildNewAnnotation = (builder: AnnotationBuilder): Annotation | undefined => {
    let newAnnotation: Annotation | undefined
    if (annotationType === AnnotationType.System || annotationType === AnnotationType.New) {
      newAnnotation = builder.finishNew()
    } else if (annotationType === AnnotationType.User) {
      if (queries.annotationQuery.data !== undefined) {
        newAnnotation = builder.finishUpdate(queries.annotationQuery.data)
      } else {
        dispatch({ type: 'setErrorMessage', payload: 'Update error' })
      }
    }
    return newAnnotation
  }

  const saveAnnotation = (newAnnotation: Annotation) => {
    queries.saveMutation.mutate(newAnnotation, {
      onError: () => {
        dispatch({ type: 'setSaving', payload: false })
        dispatch({ type: 'setErrorMessage', payload: 'Error saving' })
      },
      onSuccess: () => {
        dispatch({ type: 'setSaving', payload: false })
        handleDialogClosed()
      }
    })
  }

  const onConfirmDelete = () => {
    if (wgApp !== undefined) {
      if (annotationType === AnnotationType.System) {
        deleteAnnotationFromModel()
        handleDialogClosed()
      }
      else if (annotationType === AnnotationType.User) {
        dispatch({ type: 'setDeleting', payload: true })
        deleteAnnotationFromBackendAndModel()
      }
    }
  }

  const deleteAnnotationFromBackendAndModel = () => {
    if (queries.annotationQuery.data !== undefined) {
      queries.deleteMutation.mutate(queries.annotationQuery.data.id, {
        onSuccess: (success: boolean) => {
          dispatch({ type: 'setDeleting', payload: false })
          if (success) {
            deleteAnnotationFromModel()
            handleDialogClosed()
          } else {
            dispatch({ type: 'setErrorMessage', payload: 'Could not delete' })
          }
        },
        onError: (e) => {
          dispatch({ type: 'setDeleting', payload: false })
          dispatch({ type: 'setErrorMessage', payload: 'Error deleting' })
        }
      })
    } else {
      dispatch({ type: 'setDeleting', payload: false })
      dispatch({ type: 'setErrorMessage', payload: 'No id found' })
    }
  }

  const deleteAnnotationFromModel = async () => {
    if (wgApp !== undefined && selectedObject.type !== undefined) {
      const damageCategory = selectedObject.type
      wgApp.deleteAnnotation(selectedObject.id, damageCategory)
      const updatedTextureNpy = wgApp.getUVLabelmap(damageCategory)
      const stored = await serviceFactory.getLabelMapServiceClient().postLabelMap(analysisId, damageCategory, updatedTextureNpy)
      setTotalAnnotationCount(wgApp !== undefined ? wgApp.getTotalAnnotationCount() : 0)
      if (!stored) {
        dispatch({ type: 'setErrorMessage', payload: 'Could not update model' })
      }
    }
  }

  const editImage = (analysisId: number, imageName: string, annotationId: number, damageCategory: DamageCategoryShort | undefined) => {
    if (damageCategory === undefined) {
      if (state.selectedDamage === undefined) {
        dispatch({ type: 'setErrorMessage', payload: 'Set damage category before editing' })
      } else if (queries.damagesQuery.data === undefined) {
        dispatch({ type: 'setErrorMessage', payload: 'Damages not fetched yet' })
      }
      else {
        try {
          const damageType = mapFromBackendIdToModelName(queries.damagesQuery.data, state.selectedDamage)
          onEditImage(analysisId, imageName, annotationId, damageType, annotationType)
        } catch (error) {
          if (error instanceof DamageMappingError) {
            console.error(error, error.stack)
            dispatch({ type: 'setErrorMessage', payload: 'Unable to find correct mapping for the damage' })
          } else {
            throw error
          }
        }
      }
    } else {
      dispatch({ type: 'setErrorMessage', payload: '' })
      onEditImage(analysisId, imageName, annotationId, damageCategory, annotationType)
    }
  }

  const isPrimaryImage = (imageName: string | undefined, selectedImageName: string): boolean => {
    return selectedImageName !== undefined ? imageName === selectedImageName.slice(0, 36) : false
  }

  const getTitle = (): ReactNode => {
    switch (annotationType) {
      case AnnotationType.New:
        return <><AddBoxIcon color="secondary" fontSize="large" className={classes.titleIcon} />New annotation</>
      case AnnotationType.System:
        return <><SettingsApplicationsIcon color="primary" fontSize="large" className={classes.titleIcon} />System annotation ({selectedObject.id})</>
      case AnnotationType.User:
        return <><AccountBoxIcon color="primary" fontSize="large" className={classes.titleIcon} />User annotation ({selectedObject.id})</>
      default:
        return ""
    }
  }

  const getPlacement = (): Placement => {
    const primaryImage = images.find(i => isPrimaryImage(state.primaryImageName, i))
    if (primaryImage !== undefined && wgApp !== undefined && selectedObject.type !== undefined) {
      const overlay = wgApp.getAnnotationOverlay(selectedObject.id, primaryImage, selectedObject.type)
      return calcPlacement(overlay, overlayScaleFactor, overlay.width * overlayScaleFactor, overlay.height * overlayScaleFactor);
    }
    return new Placement(0, 0, 0, 0)
  }

  const calcDamageColor = () => getDamageColor(selectedObject.type, wgApp?.getLabelColors())

  const sliderMarks = [
    { value: 5, label: '0' },
    { value: 23, label: '1' },
    { value: 41, label: '2' },
    { value: 59, label: '3' },
    { value: 77, label: '4' },
    { value: 95, label: '5' }
  ]

  return (
    <>
      <CustomDialog
        open={open}
        onClose={handleDialogClosed}
        title={!isReady() ? 'Loading...' : getTitle()}
        maxWidth='lg'
      >
        {isReady() ?
          <>
            <DialogContent>
              <Box className={classes.columns}>
                <Box pr={1} pb={1} className={classes.main}>

                  {wgApp !== undefined &&
                    <SelectedImage
                      disableSetPrimary={annotationType === AnnotationType.User}
                      disableControls={state.saving}
                      src={queries.selectedImageQuery.data}
                      fetching={queries.selectedImageQuery.isFetching}
                      onEdit={() => {
                        editImage(analysisId, images[state.selectedImage], selectedObject.id, selectedObject.type)
                      }}
                      onSetAsPrimary={() => onSetAsPrimary(images[state.selectedImage])}
                      isPrimary={isPrimaryImage(state.primaryImageName, images[state.selectedImage])}
                      overlay={selectedObject.type !== undefined ? wgApp.getAnnotationOverlay(selectedObject.id, images[state.selectedImage], selectedObject.type) : undefined}
                      color={calcDamageColor()}
                    />}

                  <Box className={classes.form}>

                    <Box p={1} className={classes.info}>
                      {annotationType === AnnotationType.User &&
                        <>
                          <Typography>
                            {`Created: ${queries.annotationQuery.data?.created ? new Date(queries.annotationQuery.data.created).toLocaleString() : '?'}`}
                          </Typography>
                        </>}
                    </Box>

                    <Box p={1} className={classes.inputs}>
                      <Typography>
                        Condition rating *
                      </Typography>
                      <Slider
                        id='condition-slider'
                        step={null}
                        marks={sliderMarks}
                        value={sliderMarks[state.damageLevel].value}
                        onChange={(event: ChangeEvent<{}>, newValue: number | number[]) => onSetDamageLevel(event, newValue)}
                      />

                      <LabelCategorySelect
                        disabled={annotationType !== AnnotationType.New}
                        required={true}
                        label='Damage category'
                        items={queries.damagesQuery.data?.map(d => d.value)}
                        value={queries.damagesQuery.data?.find(d => d.id === state.selectedDamage)?.value}
                        onSelect={(selected: number) => onDamageCategorySelect(selected)}
                      />

                      {!state.causeCustom &&
                        <LabelCategorySelect
                          label='Cause'
                          items={queries.causesQuery.data?.map(c => c.value)}
                          value={queries.causesQuery.data?.find(c => c.id === state.selectedCause)?.value}
                          onSelect={(selected: number) => onDamageCauseSelect(selected)}
                        />}

                      {state.causeCustom &&
                        <TextField
                          value={state.customCause}
                          variant='outlined'
                          margin='dense'
                          id='cause-textfield'
                          label='Custom cause'
                          fullWidth
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => dispatch({ type: 'setCustomCause', payload: e.currentTarget.value })}
                        />}

                      <FormControlLabel
                        control={
                          <Switch
                            checked={state.causeCustom}
                            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => dispatch({ type: 'setCauseCustom', payload: checked })}
                            name='show-custom-cause'
                            color='primary'
                          />
                        }
                        label='Custom cause'
                      />

                      <TextField
                        required
                        variant='outlined'
                        label='Description'
                        margin='dense'
                        id='description-textfield'
                        fullWidth
                        multiline
                        rows='5'
                        value={state.description}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch({ type: 'setDescription', payload: e.target.value })}
                      />
                    </Box>

                  </Box>
                </Box>

                <Box className={classes.images} pl={1} pr={1} pb={1}>
                  {wgApp !== undefined && queries.imageQuery.data?.map((image, i) =>
                    <Thumbnail
                      key={i}
                      src={image}
                      overlay={selectedObject.type !== undefined ? wgApp.getAnnotationOverlay(selectedObject.id, images[i], selectedObject.type) : undefined}
                      selected={i === state.selectedImage}
                      isPrimary={isPrimaryImage(state.primaryImageName, images[i])}
                      onSelect={() => dispatch({ type: 'setSelectedImage', payload: i })}
                      onEdit={() => {
                        editImage(analysisId, images[i], selectedObject.id, selectedObject.type)
                      }}
                      color={calcDamageColor()}
                    />)
                  }
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <Typography color='error'>
                {state.errorMessage}
              </Typography>
              <Button
                onClick={handleDialogClosed}
                color='primary'
                variant='outlined'
                disabled={state.saving}
              >
                Cancel
              </Button>
              <Button
                onClick={() => setAlertDialogOpen(true)}
                color='primary'
                variant='outlined'
                disabled={state.saving || state.deleting || annotationType === AnnotationType.New}
              >
                {state.deleting ? 'Deleting...' : 'Delete'}
              </Button>
              <Button
                onClick={onSaveClicked}
                color='primary'
                variant='outlined'
                disabled={state.saving}
              >
                {state.saving ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </>
          :
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        }
      </CustomDialog>

      <AlertDialog
        title="Delete annotation"
        body="Are you sure you want to delete the annotation?"
        noLabel="No"
        yesLabel="Yes"
        onSetOpen={(open: boolean) => setAlertDialogOpen(open)}
        open={alertDialogOpen}
        onYes={() => onConfirmDelete()}
      />
    </>
  )
}

export { AnnotationDialog }