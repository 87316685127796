import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  hideForPrint: {
    '@media print': {
      display: 'none',
    },
  },
  showForPrint: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  frontPage: {
    '@media print': {
      pageBreakAfter: 'always',
    },
  },
  selectedPages: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  button: {
    marginRight: theme.spacing(2)
  },
}))

export default useStyles
