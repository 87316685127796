import { makeStyles } from '@material-ui/core'

const width = 1032

export const useStyles = makeStyles(theme => ({
  columns: {
    width,
    display: 'flex'
  },
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  form: {
    display: 'flex',
  },
  info: {
    flex: 1
  },
  inputs: {
    flex: 1
  },
  images: {
    // TODO: Scroll images inside dialog
    overflowY: 'auto',
    boxSizing: 'border-box'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    width,
    height: 600,
  },
  titleIcon: {
    float: 'left',
    paddingRight: theme.spacing(1),
  },
}))

export default useStyles
