import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  appBar: {
    '@media print': {
      display: 'none'
    },
  },
  linkAppBar: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  content: {
    '@media print': {
      marginTop: 0,
    },
  },
  progressContainer: {
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(3),
  },
}))

export default useStyles
