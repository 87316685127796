import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  imgWithoutOverlay: {
    width: 250,
  },
  selected: {
    boxSizing: 'border-box',
    border: 'solid 2px ' + theme.palette.primary.main,
  },
  container: {
    position: 'relative'
  },
  primaryIcon: {
    fontSize: 32,
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    background: 'white',
    borderRadius: '50%',
  },
  editIcon: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
  },
}))

export default useStyles
