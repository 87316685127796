import { Button, DialogActions, DialogContent, Box, Slider, Grid, Typography, FormControlLabel, Checkbox, Tooltip, IconButton } from '@material-ui/core'
import { ChangeEvent, FC, SetStateAction, useEffect, useState } from 'react'
import { CustomDialog } from 'src/components/CustomDialog'
import { MLTrainingGrid } from 'src/components/mltraininggrid/MLTrainingGrid'
import { BitmapImage, ColorChannel } from 'src/components/mltraininggrid/BitmapImage'
import { useApp } from 'src/components/AppContextProvider'
import { AnnotationType } from 'src/components/DialogController'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import BrushIcon from '@material-ui/icons/Brush';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SelectedObject } from 'src/components/Analysis'
import { DamageCategoryShort } from 'src/datamodel/DamageMapping'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { getDamageColor } from 'src/util/annotationUtil'

interface EditImageDialogProps {
  onSaved: (selectedObject: SelectedObject, damageCategory: DamageCategoryShort, newId?: number) => void
  onClose: () => void
  onCancel: () => void
  selectedObject: SelectedObject
  open: boolean
  title: string
  imageName: string
  damageCategory: DamageCategoryShort
  annotationId: number
  analysisId: number
  annotationType: AnnotationType
}

export const overlayScaleFactor = 8

export const EditImageDialog: FC<EditImageDialogProps> = ({ onSaved, onClose, onCancel, selectedObject, open, title, imageName, annotationId, damageCategory, analysisId, annotationType }: EditImageDialogProps) => {
  const zoomMin = 0
  const zoomMax = 10
  const brushSizeMin = 1
  const brushSizeMax = 6
  const gridScale = 8
  const uiScale = 4
  const [saving, setSaving] = useState<boolean>(false)
  const [zoom, setZoom] = useState<number>(zoomMin)
  const { wgApp, serviceFactory } = useApp()
  const [mlMask, setMlMask] = useState<BitmapImage | undefined>()
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const [drawMode, setDrawMode] = useState<boolean>(true)
  const [brushSize, setBrushSize] = useState<number>(1)
  const [isDrawingEmpty, setDrawingEmpty] = useState<boolean>(true)
  const [isOverlayVisible, setOverlayVisible] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onSaveClicked = (): void => {
    if (mlMask !== undefined && wgApp !== undefined) {
      switch (annotationType) {
        case AnnotationType.New:
          const overlayDataNew = createOverlay(mlMask, gridScale, overlayScaleFactor)
          const newId = wgApp.createAnnotation(imageName, damageCategory, overlayDataNew)
          storeAnnotation(newId)
          break
        case AnnotationType.User:
        case AnnotationType.System:
          const overlayData = createOverlay(mlMask, gridScale, overlayScaleFactor)
          wgApp.updateAnnotationInImage(imageName, annotationId, damageCategory, overlayData)
          storeAnnotation()
          break
        case AnnotationType.Unknown:
          console.error('Annotation type unknown')
          break
      }
    }
  }

  const storeAnnotation = async (newId?: number) => {
    if (mlMask !== undefined && wgApp !== undefined) {
      setSaving(true)
      reset()
      const updatedTextureNpy = wgApp.getUVLabelmap(damageCategory)
      const stored = await serviceFactory.getLabelMapServiceClient().postLabelMap(analysisId, damageCategory, updatedTextureNpy)
      setSaving(false)

      if (stored) {
        onSaved(selectedObject, damageCategory, newId)
      } else {
        setErrorMessage('Error: Damage update not stored in backend.')
        console.error("Damage area update not stored in backend")
      }
    }
  }

  const createOverlay = (scaledBitmap: BitmapImage, gridScale: number, overlayScaleFactor: number): ImageData => {
    scaledBitmap.scaleUp(gridScale / overlayScaleFactor)
    const rgbaData = scaledBitmap.toRGBAData()
    return new ImageData(rgbaData, scaledBitmap._width, scaledBitmap._height)
  }

  const onCancelClicked = (): void => {
    reset()
    onCancel()
  }

  useEffect(() => {
    if (open && wgApp !== undefined) {
      setSaving(false)
      serviceFactory.getImageServiceClient().getImageUrl(analysisId, imageName).then(url => setImageUrl(url))

      // The annotation overlay is given in a scaled down version (factor is 8).
      // So each pixel in the overlay we receive here represents 8x8 pixels in the original image.
      const overlayImage = wgApp.getAnnotationOverlay(annotationId, imageName, damageCategory)
      const bitmapImage = BitmapImage.fromRGBAData(overlayImage.data,
        overlayImage.width,
        overlayImage.height,
        ColorChannel.Red)
  
      // Scale bitmap down even further to get to grid scale.
      bitmapImage.scaleDown(gridScale / overlayScaleFactor)
  
      setMlMask(bitmapImage)
    }
  }, [open, wgApp, analysisId, annotationId, damageCategory, imageName, serviceFactory])

  useEffect(() => {
    if (isDrawingEmpty) {
      setZoom(zoomMin)
    }
  }, [isDrawingEmpty])

  const reset = () => {
    setErrorMessage('')
    setZoom(zoomMin)
    setBrushSize(brushSizeMin)
    setDrawMode(true)
    setOverlayVisible(true)
  }

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (e.code.indexOf('Digit') !== -1) {
        const size = parseInt(e.code.split('Digit')[1])
        setBrushSize(size <= brushSizeMax ? size : brushSizeMax)
      }
      switch (e.code) {
        case 'KeyD':
          setDrawMode(true)
          break
        case 'KeyE':
          setDrawMode(false)
          break
        case 'KeyC':
          setOverlayVisible(!isOverlayVisible)
          break
        case 'KeyA':
          increaseSlider(setZoom, zoom, zoomMax, 1)
          break
        case 'KeyZ':
          decreaseSlider(setZoom, zoom, zoomMin, 1)
          break
      }
    }
    document.addEventListener('keydown', keyDown)
    return () => {
      document.removeEventListener('keydown', keyDown)
    }
  }, [isOverlayVisible, zoom])

  const getBrushSizes = () => {
    let sizes = []
    for (let i = brushSizeMin; i <= brushSizeMax; i++) {
      sizes.push(i)
    }
    return sizes.join(',')
  }
  
  const decreaseSlider = (setFunc: (value: SetStateAction<number>) => void, value: number, min: number, step: number) => 
    setFunc(value - step < min ? min : value - step)

  const increaseSlider = (setFunc: (value: SetStateAction<number>) => void, value: number, max: number, step: number) => 
    setFunc(value + step > max ? max : value + step)

  return (
    <CustomDialog
      open={open}
      title={title}
      onClose={() => {
        reset()
        onClose()
      }}
      scroll='body'
      maxWidth='xl'
    >
      <DialogContent>
        {mlMask !== undefined && imageUrl !== undefined && !saving &&
          <MLTrainingGrid
            backgroundImageUrl={imageUrl}
            mlMask={mlMask}
            width={mlMask.getWidth() * gridScale}
            height={mlMask.getHeight() * gridScale}
            gridScale={gridScale}
            uiScale={uiScale}
            zoom={zoom}
            maxZoomFactor={16}
            zoomMax={zoomMax}
            drawMode={drawMode}
            brushSize={brushSize}
            setBitmapEmpty={(isEmpty: boolean) => setDrawingEmpty(isEmpty)}
            overlayVisible={isOverlayVisible}
            drawColor={getDamageColor(damageCategory, wgApp?.getLabelColors())}
          />
        }
        {saving && 
          'Saving...'
        }
      </DialogContent>
      <DialogActions>
        <Box mr={2}>
          <Tooltip title={`Shortcuts: [d]: Draw, [e]: Erase, [c]: Overlay, [${getBrushSizes()}]: Brush size, [a,z]: Zoom`}>
            <HelpOutlineIcon color="primary" />
          </Tooltip>
        </Box>
        <FormControlLabel
            key="show-overlay"
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={isOverlayVisible}
                onChange={() => setOverlayVisible(!isOverlayVisible)}
                name="show-overlay"
                disabled={saving}
                color="primary"
              />
            }
            label="Show overlay"
          />
        <Box width={200} mr={2}>
          <Grid container spacing={2}>
            <Grid item>
              <IconButton size="small" onClick={(e) => decreaseSlider(setBrushSize, brushSize, brushSizeMin, 1)}>
                <BrushIcon color='primary' fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Slider
                value={brushSize}
                min={brushSizeMin}
                max={brushSizeMax}
                marks={true}
                onChange={(event: ChangeEvent<{}>, newValue: number | number[]) => setBrushSize(newValue as number)}
              />
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={(e) => increaseSlider(setBrushSize, brushSize, brushSizeMax, 1)}>
                <BrushIcon color='primary'/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box mr={2}>
          <Button
            onClick={() => setDrawMode(true) }
            color='primary'
            size='small'
            variant={drawMode ? 'contained' : 'outlined'}
            disabled={saving}
            startIcon={<EditIcon />}
          >
            Draw
          </Button>
          <Button
            onClick={() => setDrawMode(false) }
            color='primary'
            size='small'
            variant={!drawMode ? 'contained' : 'outlined'}
            disabled={saving}
            startIcon={<DeleteIcon />}
          >
            Erase
          </Button>
        </Box>
        <Box width={200} mr={2}>
          <Grid container spacing={2}>
            <Grid item>
              <IconButton size="small" onClick={(e) => decreaseSlider(setZoom, zoom, zoomMin, 1)}>
                <ZoomOutIcon color='primary'/>
              </IconButton>
            </Grid>
            <Grid item xs>
              <Slider
                value={zoom}
                disabled={isDrawingEmpty}
                min={zoomMin}
                max={zoomMax}
                step={0.1}
                onChange={(event: ChangeEvent<{}>, newValue: number | number[]) => setZoom(newValue as number)}
              />
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={(e) => increaseSlider(setZoom, zoom, zoomMax, 1)}>
                <ZoomInIcon color='primary' />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box mr={2}>
          <Button
            onClick={onCancelClicked}
            color='primary'
            size='small'
            variant='outlined'
            disabled={saving}
          >
            Cancel
          </Button>
          <Button
            onClick={onSaveClicked}
            color='primary'
            size='small'
            variant='outlined'
            disabled={saving || isDrawingEmpty}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </DialogActions>
      {errorMessage !== '' && 
        <Box pr={2} pb={1}>
          <Typography align='right' color='error'>
            {errorMessage}
          </Typography>
        </Box>
      }
    </CustomDialog>
  )
}
