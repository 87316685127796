import Select from '@material-ui/core/Select'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'
import { ChangeEvent, FC } from 'react'

interface LabelCategorySelectProps {
  required?: boolean
  label: string
  items: string[] | undefined
  value: string | undefined
  disabled?: boolean
  onSelect: (selected: number) => void
}
export const LabelCategorySelect: FC<LabelCategorySelectProps> = (props: LabelCategorySelectProps) => {
  const { required, disabled, label, items, value, onSelect } = props

  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    onSelect(items?.findIndex(i => i === event.target.value) as number)
  }

  return (
    <FormControl
      required={required}
      fullWidth
      margin='dense'
    >
      <InputLabel id='select'>{label}</InputLabel>
      <Select
        disabled={disabled}
        onChange={handleChange}
        value={value !== undefined ? value : ''}
      >
        {items?.map((value, index) => {
          return <MenuItem key={index} value={value}>{value}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
}
