import { Box, Button, CircularProgress } from '@material-ui/core'
import useStyles from './SelectedImage.style'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import { FC } from 'react'
import { ImageWithOverlay } from 'src/components/ImageWithOverlay'

interface SelectedImageProps {
  disableControls: boolean
  disableSetPrimary: boolean
  src: string | undefined
  fetching: boolean
  onEdit: () => void
  onSetAsPrimary: () => void
  isPrimary: boolean
  overlay: ImageData | undefined
  color: string
}

export const SelectedImage: FC<SelectedImageProps> = ({ disableControls, disableSetPrimary, src, fetching, onEdit, onSetAsPrimary, isPrimary, overlay, color }: SelectedImageProps) => {
  const classes = useStyles()

  return (
    <>
      {src !== undefined && !fetching ?
        <Box className={classes.imageContainer}>
          {overlay !== undefined ?
            <ImageWithOverlay
              src={src}
              overlay={overlay}
              scaleFactor={1} 
              color={color}
            />
            :
            <img src={src} className={classes.imgWithoutOverlay} alt="selected" />
          }
          <Box className={classes.imageControls} p={1}>
            <Button
              disabled={disableControls}
              className={classes.imageControl}
              size='small'
              onClick={onEdit}
              variant='contained'
              color='primary'
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
            {isPrimary ?
              <Button
                size='small'
                variant='contained'
                color='secondary'
                startIcon={<CheckIcon />}
              >
                Primary
              </Button>
              : 
              <>
                {!disableSetPrimary && 
                  <Button
                    onClick={onSetAsPrimary}
                    size='small'
                    variant='contained'
                    color='primary'
                    startIcon={<CheckIcon />}
                  >
                    Set as primary
                  </Button>
                }    
              </>
            }
          </Box>
        </Box> 
        :
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      }
    </>
  )
}
