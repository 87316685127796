import { ServiceClient } from "src/datasource/ServiceClient"
import { UserServiceClient } from "src/datasource/UserServiceClient";

/*
 * Uses blob storage to fetch images 
 */
export class BlobImageServiceClient {
  private readonly client: ServiceClient
  private readonly userServiceClient: UserServiceClient
  private readonly twoFiftyPxEnding = "-250Px.jpg"
  private readonly highQualityEnding = "-HighQuality.jpg"

  constructor (client: ServiceClient, userServiceClient: UserServiceClient) {
    this.client = client;
    this.userServiceClient = userServiceClient
  }
  
  async getImageUrl (analysisId: number, imageName: string): Promise<string> {
    const tenantId = await this.getTenantId()
    const restUrl = 'api/modelblob/analysis/' + analysisId + '/image/' + imageName + '/tenant/' + tenantId
    const request = await this.client.getRequestResponse(restUrl)
    return request.text()
  }

  private async getTenantId (): Promise<number> {
    return this.userServiceClient.getCurrentTenantId()
  }

  /*
   * An example:
   * getImageAsObjectUrl(105, 0a0cfe50-6b6f-4993-ae7f-0e881c83b859-HighQuality.jpg)
   */
  async getImage (analysisId: number, imageBlobName: string): Promise<Blob> {
    const link = await this.getImageUrl(analysisId, imageBlobName)
    const file_response = await fetch(link, {
      method: 'GET'
    })
    return file_response.blob()
  }

  async getImageAsObjectUrl (analysisId: number, imageBlobName: string): Promise<string> {
    return URL.createObjectURL(await this.getImage(analysisId, imageBlobName))
  }

  public async getImagesAsObjectUrls (analysisId: number, imageNames: string[]): Promise<string[]> {
    return await Promise.all(imageNames.map(async i => await this.getImageAsObjectUrl(analysisId, i)))
  }

  public convertImageNameToHq(imageName: string): string {
    return this.toImageUuid(imageName) + this.highQualityEnding
  }

  public convertImageNamesToHq(imageNames: string[]): string[] {
    return imageNames.map(imageName => this.convertImageNameToHq(imageName))
  }

  public convertImageNameTo250px(imageName: string): string {
    return this.toImageUuid(imageName) + this.twoFiftyPxEnding
  }

  public convertImageNamesTo250px(imageNames: string[]): string[] {
    return imageNames.map(imageName => this.convertImageNameTo250px(imageName))
  }

  private toImageUuid(imageName: string): string {
    return imageName.substring(0, 36)
  }
}
