import { Dialog, DialogTitle, IconButton } from '@material-ui/core'
import { ReactNode } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import useStyles from 'src/components/CustomDialog.style'

interface CustomDialogProps {
  title: ReactNode
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  onClose: () => void
  open: boolean
  scroll?: 'paper' | 'body' | undefined
  children?: ReactNode
}

export const CustomDialog = ({ title, maxWidth = undefined, onClose, open, scroll = undefined, children }: CustomDialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      scroll={scroll}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  )
}
