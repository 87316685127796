import { Box } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import useStyles from 'src/components/Thumbnail.style'
import { FC, MouseEvent, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { IconButton } from '@material-ui/core'
import { ImageWithOverlay } from 'src/components/ImageWithOverlay'

interface ThumbnailProps {
  src: string
  overlay: ImageData | undefined
  isPrimary: boolean
  selected: boolean
  color: string
  onSelect: () => void
  onEdit: () => void
}

export const Thumbnail: FC<ThumbnailProps> = (props: ThumbnailProps) => {
  const { src, overlay, isPrimary, selected, color, onSelect, onEdit } = props
  const classes = useStyles()
  const [isMouseOver, setMouseOver] = useState<boolean>(false)

  return (
    <Box 
      mb={1} 
      className={classes.container}
      onMouseOver={(e: MouseEvent<HTMLElement>) => setMouseOver(true)} 
      onMouseLeave={(e: MouseEvent<HTMLElement>) => setMouseOver(false)}
    >
      {overlay !== undefined ?
        <ImageWithOverlay
            src={src}
            overlay={overlay}
            scaleFactor={3} 
            selected={selected}
            onClick={onSelect}
            color={color}
        />
        :
        <img src={src} className={[classes.imgWithoutOverlay, selected ? classes.selected : ''].join(' ')} onClick={onSelect} alt="thumbnail" />
      }
      {isPrimary && <CheckCircleIcon color='secondary' className={classes.primaryIcon} />}
      {isMouseOver && 
        <IconButton 
          onClick={onEdit}
          size="small" 
          className={classes.editIcon} >
          <EditIcon color='primary' />
        </IconButton>
      }
    </Box>
  )
}
