// I have disabled the rule that all class names should start with
// a capital letter. I think that should be enabled later on, to
// make all the code adhere to StandardJS but that would entail
// updating all the wg* classes.
// See: https://eslint.org/docs/rules/new-cap
/* eslint new-cap: 0 */

// Interface definition.
import { WgAppInterface } from 'src/wgapp/WgAppInterface.js'
import { damageCategoriesShort } from 'src/datamodel/DamageMapping'

// External ////////////////////////////////////////////////////////
import { quat, vec3, vec4, mat4 } from 'gl-matrix'
import { parse } from '@loaders.gl/core'
import { GLTFLoader } from '@loaders.gl/gltf'

// wgtek ///////////////////////////////////////////////////////////
import { wgContext } from 'wgTek/wgContext'
import { wgResourceCache } from 'wgTek/wgResourceCache.js'
import { wgPrimRender } from 'wgTek/wgPrimRender.js'
import { wgTexture } from 'wgTek/wgTexture.js'
import { wgFbo } from 'wgTek/wgFbo.js'
import { VertexType, wgVertexDescriptor } from 'wgTek/wgVertexDescriptors.js'
import { wgVertexBuffer, wgIndexType } from 'wgTek/wgVertexBuffer.js'
import { wgShader } from 'wgTek/wgShader.js'
import { wgMeshBuilder } from 'wgTek/wgMeshBuilder.js'

// wfun ////////////////////////////////////////////////////////////
import { MouseEventHandler, MouseButtons } from 'wFundament/MouseEventHandler.js'
import { KeyboardEventHandler } from 'wFundament/KeyboardEventHandler.js'
import { TouchEventHandler } from 'wFundament/TouchEventHandler.js'
import { wOrbitCamera } from 'wFundament/wOrbitCamera.js'
import { wGizmo, wGizmoMode } from 'wFundament/wGizmo.js'

// app /////////////////////////////////////////////////////////////
class WgApp extends WgAppInterface {
  constructor (canvas) {
    super()

    const gl = canvas.getContext('webgl2', { preserveDrawingBuffer: true })

    this.glContext_ = new wgContext(gl, canvas)
    this.gl = this.glContext_.gl()
    this.frameCount_ = 0
    this.lastFps_ = 0
    this.resourceCache_ = new wgResourceCache() // a convenient way to share textures and other graphics resources.

    this.cameraPosition_ = vec3.fromValues(-15.0, 0.0, 10.0)
    this.orbitCamera_ = new wOrbitCamera('orbitCamera')
    this.orbitCamera_.setViewport(this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight())
    this.orbitCamera_.setOrbitPivot([0, -5, 0])
    this.orbitCamera_.setCameraPosition(this.cameraPosition_)
    this.orbitCamera_.setPerspective(60.0)
    this.orbitCamera_.setUpvector(vec3.fromValues(0.0, 0.0, 1.0))
    this.orbitCamera_.setZoomSpeed(4.0)
    this.cameraActiveCounter_ = 0 // used to make sure that the active camera keeps the focus

    this.primRender_ = null
    this.primRenderIdAsColor_ = null
    this.gizmo_ = new wGizmo(15.0)
    this.gizmo_.intersectionTolerance_ = 0.5
    this.gizmo_.setGizmoMode(wGizmoMode.INACTIVE)

    this.readBackFbo_ = null
    this.readBackTexture_ = null
    this.readbackPixels_ = new Uint8Array(canvas.width * canvas.height * 4)
    this.selectedObject_ = -1
    this.hoveredLabel_ = -1

    this.maskFbo_ = null
    this.maskTexture_ = null

    this.transform_ = mat4.create()

    this.primTransform_ = mat4.create()
    // mat4.fromXRotation(this.primTransform_, Math.PI / 2.0)

    this.vertexBuffer_ = new wgVertexBuffer('vbo', this.glContext_)
    this.colorTexture_ = new wgTexture('color', this.glContext_)
    this.labelTexture_ = new wgTexture('label', this.glContext_) // texture array. Each layer is a damage type and texels are IDs
    this.labelVisibiltyTexture_ = new wgTexture('labelVisiblity', this.glContext_) // Lookup table for 16bit labels
    this.labelVisibiltyTexture_.create2D(gl.R8, 256, 256, true, false)

    this.imageAnnotationTexture_ = new wgTexture('annotation', this.glContext_)
    this.combinedOverlayFbo_ = null
    this.combinedLabelTexture_ = null // 2d texture. Texel value is the damage type

    this.shader_ = new wgShader('shader', this.glContext_)
    this.idshader_ = new wgShader('idshader', this.glContext_)
    this.maskShader_ = new wgShader('maskshader', this.glContext_)
    this.combineOverlayShader_ = new wgShader('combineShader', this.glContext_)
    this.meshUvMaskShader_ = new wgShader('meshUvShader', this.glContext_)
    this.posDepthShader_ = new wgShader('posDepthShader', this.glContext_)

    this.labelmapDamageCategoriesData_ = []

    this.damageOpacity_ = 0.5
    this.visibleDamageCategories_ = damageCategoriesShort
    this.visibleDamageCategoriesBitmask_ = 255

    this.cameraFeatures_ = null

    const meshBuilder = new wgMeshBuilder('builder', this.glContext_)
    this.quadVbo_ = meshBuilder.buildFullscreenQuad('quad')

    const fullScreenQuadVssrc = `#version 300 es
    in vec4 Pos;
    void main()
    {
    gl_Position = vec4(Pos.xy, 0.0, 1.0);
    }`
    const combineOverlayFsSrc = `#version 300 es
    precision mediump float;
    out vec4 outColor;
    uniform mediump usampler2DArray labelTexture;
    uniform mediump sampler2D visibilityLookupTexture;
    uniform int visibleBitmask;
    void main()
    {
        ivec2 pxpos = ivec2(gl_FragCoord.xy);
        int labelType = 0;

        outColor = vec4(0.0);
        for ( int i = 0; i < textureSize(labelTexture, 0).z; i++ )
        {
        if ( (( uint(visibleBitmask) >> uint(i) ) & 1u) != 0u )
        {
            uvec2 labelForType = texelFetch(labelTexture, ivec3(pxpos, i) , 0).rg;

            if ( (labelForType.x != 0u) || (labelForType.y != 0u)){
                float labelVisibilty = texelFetch(visibilityLookupTexture, ivec2(labelForType), 0).r;
                if ( labelVisibilty > 0.0 ){
                    outColor = vec4(float(labelForType.r)/255.0, float(labelForType.g)/255.0, 0.0, float(i+1)/255.0);
                }
            }
        }
        }
    }
    `

    const vssrc = `#version 300 es
        in vec3 Pos;
        in vec2 Texcoord;
        uniform mat4 VP;
        out vec2 uv;
        void main()
        {
        uv = Texcoord;
        gl_Position = VP * vec4(Pos, 1.0);
        }
        `
    // Color texture with labels
    const labelColors = this.getLabelColors()

    const fssrc = `#version 300 es
        precision mediump float;
        uniform sampler2D colorTexture;
        uniform sampler2D labelTexture;
        uniform float opacity;
        uniform int highlightedLabel;
        out vec4 outColor;
        in vec2 uv;
        const vec4 lut[5] = vec4[5](
                vec4${labelColors[0]},
                vec4${labelColors[1]},
                vec4${labelColors[2]},
                vec4${labelColors[3]},
                vec4${labelColors[4]}
        );
        void main()
        {
            outColor = texture(colorTexture, vec2(uv.s, uv.t));
            vec4 labelColor = outColor;

            ivec4 labelTexel = ivec4(255.0*texture(labelTexture, uv));
            int label = labelTexel.a;
            if ( label > 0 && label < 6 ){
                labelColor = lut[label-1];

                if ( highlightedLabel > 0 && highlightedLabel == ( labelTexel.r  + labelTexel.g * 256  ) )
                {
                    outColor.rgb += 0.2;
                }
                else
                {
                    outColor = (1.0-opacity)*outColor + opacity*labelColor;
                }
            }
        }
        `
    const depthVsSrc = `#version 300 es
        in vec3 Pos;
        uniform mat4 VP;
        out vec4 positionDepth;
        void main()
        {
        gl_Position = VP * vec4(Pos, 1.0);
        positionDepth = vec4(Pos, gl_Position.z);
        }
    `

    const depthFsSrc = `#version 300 es
        precision mediump float;
        in vec4 positionDepth;
        layout(location=0) out uvec4 outPos;
        void main()
        {
            outPos = floatBitsToUint(positionDepth);
        }
    `

    // Render Id in RG and damage type in A
    const idfssrc = `#version 300 es
        precision mediump float;
        uniform sampler2D colorTexture;
        uniform sampler2D visibilityLookupTexture;

        uniform int visibleBitmask;
        uniform mediump usampler2DArray labelTexture;


        out vec4 outColor;
        in vec2 uv;
        void main()
        {
        ivec3 uvPx;
        uvPx.xy = ivec2(vec2(textureSize(labelTexture, 0).xy) * uv);


        uvec2 labelRg = uvec2(0,0);
        int labelType = 0;
        for ( int i = 0; i < textureSize(labelTexture, 0).z; i++ )
        {

          if ( (( uint(visibleBitmask) >> uint(i) ) & 1u) != 0u )
          {
            uvPx.z = i;
            uvec2 labelForType = texelFetch(labelTexture, uvPx, 0).rg;

            if ( labelForType.x > 0u || labelForType.y > 0u )
            {
                float labelVisibilty = texelFetch(visibilityLookupTexture, ivec2(labelForType), 0).r;
                if ( labelVisibilty > 0.0 ){
                    labelRg = labelForType;
                    labelType = i+1;
                }
            }
          }
        }
        outColor = vec4(vec2(labelRg)/255.0, 0.0, float(labelType)/255.0);
        }
        `
    // Render binary image for labels of a specific type
    const maskFssrc = `#version 300 es
        precision mediump float;

        uniform mediump usampler2DArray labelTexture;
        uniform int labelType;
        uniform int selectedAnnotationId;

        out vec4 outColor;
        in vec2 uv;
        void main()
        {
          ivec3 uvPx;
          uvPx.xy = ivec2(vec2(textureSize(labelTexture, 0).xy) * uv);
          uvPx.z = labelType;

          uvec2 labelForType = texelFetch(labelTexture, uvPx, 0).rg;

          float mask = 0.0;
          int annotationId = 256*int(labelForType.y) + int(labelForType.x);
          if ( annotationId > 0 && ((annotationId == selectedAnnotationId) || selectedAnnotationId == 0) )
          {
            mask = 1.0;
          }
          outColor = vec4(mask);
          //outColor = vec4(1.0);
        }
        `
    const meshUvInsideMaskFsSrc = `#version 300 es
        precision mediump float;
        in vec2 uv;
        uniform mediump sampler2D maskTexture;
        uniform vec2 viewport;
        out vec4 outColor;
        void main()
        {
            // Screenspace uv
            //vec2 ssUv = gl_FragCoord.xy / vec2(textureSize(maskTexture, 0).xy);
            vec2 ssUv = gl_FragCoord.xy / viewport;
            outColor = vec4(0.0);
            // Lookup mask in screenspace
            float maskValue = texture(maskTexture, ssUv).r;
            // Output mesh uv if fragment is inside mask
            if ( maskValue > 0.0 ){
            highp uint pack = packUnorm2x16(uv); // Note uv.s is LSB, uv.t is MSB
            //outColor = unpackUnorm4x8( pack ); // Doesnt work?
            outColor = vec4((pack >> 8u)&0xFFu, pack&0xFFu, (pack >> 24u)&0xFFu,(pack >> 16u)&0xFFu) / 255.0;
            }
        }
    `
    this.shader_.createShaderProgram(vssrc, fssrc, { Pos: 0, Texcoord: 2 })
    this.idshader_.createShaderProgram(vssrc, idfssrc, { Pos: 0, Texcoord: 2 })
    this.maskShader_.createShaderProgram(vssrc, maskFssrc, { Pos: 0, Texcoord: 2 })
    this.combineOverlayShader_.createShaderProgram(fullScreenQuadVssrc, combineOverlayFsSrc, { Pos: 0 })
    this.meshUvMaskShader_.createShaderProgram(vssrc, meshUvInsideMaskFsSrc, { Pos: 0, Texcoord: 2 })
    this.posDepthShader_.createShaderProgram(depthVsSrc, depthFsSrc, { Pos: 0 })

    // event handlers
    this.onLeftClickCallback = null
    this.onRightClickCallback = null
    this.onDamageHoverCallback_ = null

    this.keyboardEventHandler_ = new KeyboardEventHandler()
    this.mouseEventHander_ = new MouseEventHandler()
    this.touchEventHandler_ = new TouchEventHandler()

    this.appInfo_ = {
      appName: 'Fbo read back Demo',
      appDeveloper: 'Trier & Kjeldsen',
      fps: 0
    }

    this.pickingInfo_ = {
      object: 'unknown'
    }

    this._dataModel = null

    this.init()

    this.scheduleRender_ = true
    let lastTimeStamp = 0
    const loop = (timestamp) => {
      const deltaTime = timestamp - lastTimeStamp
      if (this.update(deltaTime / 100.0) || this.scheduleRender_) {
        this.render(timestamp)
        this.scheduleRender_ = false
      }

      lastTimeStamp = timestamp

      requestAnimationFrame(loop)
    }
    requestAnimationFrame(loop)
  }

  getKeyboardEventHandler () { return this.keyboardEventHandler_ }
  getMouseEventHandler () { return this.mouseEventHander_ }
  getTouchEventHandler () { return this.touchEventHandler_ }

  async setDataModel (dataModel, onComplete = () => {}) {
    this._dataModel = dataModel
    await this.loadMesh()
    onComplete()
  }

  /*
   * Internal. Load mesh and textures from this.datamodel_
   */
  async loadMesh () {
    const gl = this.glContext_.gl()

    this.cameraFeatures_ = await this._dataModel.opensfm()

    parse(this._dataModel.gltfModel(), GLTFLoader, { worker: false }).then((gltf) => {
      const scene = gltf.scene
      let node = scene.nodes[0]

      const transform = mat4.create()

      if (node.matrix) {
        mat4.copy(transform, node.matrix)
      } else {
        const translation = vec3.create()
        const rotation = quat.create()
        const scale = vec3.fromValues(1, 1, 1)
        if (node.translation) {
          vec3.copy(translation, node.translation)
        }
        if (node.rotation) {
          quat.copy(rotation, node.rotation)
        }
        if (node.scale) {
          vec3.copy(scale, node.scale)
        }
        mat4.fromRotationTranslationScale(transform, rotation, translation, scale)
      }
      // GLTF is Y up as standard
      const toZup = mat4.create()
      mat4.fromXRotation(toZup, Math.PI / 2)
      mat4.multiply(this.transform_, toZup, transform)

      let mesh = node.mesh
      while (mesh === undefined) {
        if (node === undefined) {
          break
        }
        node = node.children[0]
        mesh = node.mesh
      }
      const material = mesh.primitives[0].material

      const vertexCount = mesh.primitives[0].attributes.POSITION.count
      const posAttr = mesh.primitives[0].attributes.POSITION.value
      const normalAttr = mesh.primitives[0].attributes.NORMAL.value
      const uvAttr = mesh.primitives[0].attributes.TEXCOORD_0.value

      const desc = new wgVertexDescriptor(VertexType.VT_VertexNormalUv3, this.glContext_.gl())
      const vertexData = new Float32Array(vertexCount * 8)
      for (let i = 0; i < vertexCount; i++) {
        for (let j = 0; j < 3; j++) {
          vertexData[8 * i + j] = posAttr[3 * i + j]
        }
        for (let j = 0; j < 3; j++) {
          vertexData[8 * i + 3 + j] = normalAttr[3 * i + j]
        }
        for (let j = 0; j < 2; j++) {
          vertexData[8 * i + 6 + j] = uvAttr[2 * i + j]
        }
      }

      const indices = mesh.primitives[0].indices

      let indexType = wgIndexType.wgUnsignedShort
      if (indices.componentType === gl.UNSIGNED_BYTE) {
        indexType = wgIndexType.wgUnsignedByte
      } else if (indices.componentType === gl.UNSIGNED_INT) {
        indexType = wgIndexType.wgUnsignedInt
      }

      this.vertexBuffer_.deleteVertexBuffer()
      this.vertexBuffer_.createElementsVertexBuffer(gl.STATIC_DRAW, desc.getVertexLayout(), vertexCount, desc.getVertexSizeInBytes(), indexType, indices.value, vertexData)

      if (material.pbrMetallicRoughness.baseColorTexture) {
        this.colorTexture_.deleteTexture()
        this.colorTexture_.setActiveTexture(gl.TEXTURE0)
        this.colorTexture_.create2dFromImage(gltf.images[0].image, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, false, true)
      } else {
        const image = new Image()
        image.onload = () => {
          this.colorTexture_.deleteTexture()
          this.colorTexture_.setActiveTexture(gl.TEXTURE0)
          this.colorTexture_.create2dFromImage(image, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, false, true)
          this.render()
        }
        try {
          this._dataModel.colorMap().then((colormap) => {
            image.src = colormap
          })
        } catch (error) {
          console.error(`Error loading color map: ${error}`)
        }
      }

      this.scheduleRender_ = true
    })

    const maps = await this._dataModel.labelMaps()

    this.labelmapDamageCategoriesData_ = []
    let textureInitialized = false
    let texSize
    // Parse NPY files. Data is assumed to be uint16_t
    for (let i = 0; i < damageCategoriesShort.length; i++) {
      const buf = maps[damageCategoriesShort[i].toLowerCase()]
      if (buf) {
        const view = new DataView(buf)
        const hdrlen = view.getUint16(8, true)

        let hdrStr = ''
        for (let i = 0; i < hdrlen; i++) {
          hdrStr += String.fromCharCode(view.getUint8(i + 10))
        }

        const hdr = hdrStr.match(/'shape': \((?<width>\d+), (?<height>\d+)\)/)
        texSize = hdr.groups

        if (!textureInitialized) {
          this.labelTexture_.setActiveTexture(gl.TEXTURE1)
          this.labelTexture_.deleteTexture()
          this.labelTexture_.create2DArray(gl.RG8UI, texSize.width, texSize.height, damageCategoriesShort.length, true)
          textureInitialized = true
        }

        const pixeldata = new Uint16Array(buf, 10 + hdrlen)
        const maxval = pixeldata.reduce((a, b) => { return Math.max(a, b) })
        this.labelmapDamageCategoriesData_.push({ npyData: buf, npyHdr: buf.slice(0, hdrlen + 10), width: texSize.width, height: texSize.height, pixeldata: pixeldata, nextAnnotationId: maxval + 1 })

        this.labelTexture_.bind()
        gl.texSubImage3D(gl.TEXTURE_2D_ARRAY, 0, 0, 0, i, texSize.width, texSize.height, 1, gl.RG_INTEGER, gl.UNSIGNED_BYTE, new Uint8Array(pixeldata.buffer, pixeldata.byteOffset))
        this.labelTexture_.unbind()
      }
    }
    // Set all labels visible
    this.labelVisibiltyTexture_.uploadDataToTexture(gl.RED, gl.UNSIGNED_BYTE, new Uint8Array(65536).fill(255))

    // Create the combined texture
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)

    this.scheduleRender_ = true
  }

  init () {
    // create the swap chain
    this.createFbo()
    this.primRender_ = new wgPrimRender('primitiveRender', this.glContext_)

    this.primRender_.addGridXZ(24.0, 10.01)

    // event handling
    this.glContext_.canvas_.addEventListener('keydown', (event) => { this.getKeyboardEventHandler().keyDown(event.key) })
    this.glContext_.canvas_.addEventListener('keyup', (event) => { this.getKeyboardEventHandler().keyUp(event.key) })

    this.glContext_.canvas_.addEventListener('mousedown', (event) => { this.getMouseEventHandler().onMouseDown(event) })
    this.glContext_.canvas_.addEventListener('mouseup', (event) => { this.getMouseEventHandler().onMouseUp(event) })

    const mouseDownPos = [-1, -1]
    this.glContext_.canvas_.addEventListener('mousedown', (event) => { mouseDownPos[0] = event.offsetX; mouseDownPos[1] = event.offsetY })
    this.glContext_.canvas_.addEventListener('mouseup', (event) => { if (mouseDownPos[0] === event.offsetX && mouseDownPos[1] === event.offsetY) { this.onClick(event) } })

    this.glContext_.canvas_.addEventListener('mousemove', (event) => { this.getMouseEventHandler().onMouseMove(event) })
    this.glContext_.canvas_.addEventListener('mousemove', (event) => { this.onMouseMove(event) })
    this.glContext_.canvas_.addEventListener('mouseleave', (event) => { this.getMouseEventHandler().onMouseLeave(event) })
    this.glContext_.canvas_.addEventListener('click', (event) => { this.getMouseEventHandler().onMouseClick(event) })
    this.glContext_.canvas_.addEventListener('dblclick', (event) => { this.getMouseEventHandler().onMouseDoubleClick(event) })
    this.glContext_.canvas_.addEventListener('wheel', (event) => { event.preventDefault(); this.getMouseEventHandler().onMouseWheel(event) })

    this.glContext_.canvas_.addEventListener('touchstart', (event) => { this.getTouchEventHandler().onTouchStart(event) })
    this.glContext_.canvas_.addEventListener('touchend', (event) => { this.getTouchEventHandler().onTouchEnd(event) })
    this.glContext_.canvas_.addEventListener('touchcancel', (event) => { this.getTouchEventHandler().onTouchCancel(event) })
    this.glContext_.canvas_.addEventListener('touchmove', (event) => { this.getTouchEventHandler().onTouchMove(event) })

    this.glContext_.canvas_.addEventListener('contextmenu', (e) => { e.preventDefault(); this.getMouseEventHandler().onMouseRightClick() }, false)
  }

  createFbo () {
    const gl = this.gl
    this.readBackFbo_ = new wgFbo('ReadBackFbo', this.glContext_)
    this.readBackTexture_ = new wgTexture('readBackTexture', this.glContext_)
    this.readBackTexture_.setActiveTexture(gl.TEXTURE0)
    this.readBackTexture_.create2D(gl.RGBA8, this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight(), true, false)
    this.readBackFbo_.attachTexture(this.readBackTexture_)
    this.readBackFbo_.createDepthStencilBuffer(this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight())
    this.readBackFbo_.unbind()

    this.posFbo_ = new wgFbo('posFbo', this.glContext_)
    this.posTexture_ = new wgTexture('posTexture', this.glContext_)
    this.posTexture_.setActiveTexture(gl.TEXTURE0)
    this.posTexture_.create2D(gl.RGBA32UI, this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight(), true, false)

    this.posFbo_.attachTexture(this.posTexture_)
    this.posFbo_.createDepthStencilBuffer(this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight())
    this.posFbo_.unbind()

    this.maskFbo_ = new wgFbo('maskFbo', this.glContext_)
    this.maskTexture_ = new wgTexture('maskTexture', this.glContext_)

    this.maskTexture_.setActiveTexture(gl.TEXTURE0)
    this.maskTexture_.create2D(gl.RGBA8, 1, 1, true, false)
    this.maskFbo_.attachTexture(this.readBackTexture_)
    this.maskFbo_.createDepthStencilBuffer(1, 1)
    this.maskFbo_.unbind()

    this.combinedOverlayFbo_ = new wgFbo('combinedOverlay', this.glContext_)
    this.combinedLabelTexture_ = new wgTexture('combinedLabel', this.glContext_) // 2d texture. Texel value is the damage type
    this.combinedLabelTexture_.create2D(gl.RGBA8, 2048, 2048, true, false)

    this.combinedOverlayFbo_.attachTexture(this.combinedLabelTexture_)
    this.combinedOverlayFbo_.unbind()

    this.imageOverlayFbo_ = new wgFbo('imageOverlayFbo', this.glContext_)
    this.imageOverlayFboTexture_ = new wgTexture('imageOverlayFboTex', this.glContext_)
    this.imageOverlayFboTexture_.create2D(gl.RGBA8, 1, 1, true, false)
    this.imageOverlayFbo_.attachTexture(this.imageOverlayFboTexture_)
    this.imageOverlayFbo_.createDepthStencilBuffer(1, 1)
    this.imageOverlayFbo_.unbind()
  }

  update (deltaTime) {
    let isUpdated = false

    const fx = this.getMouseEventHandler().getNormX()
    const fy = this.getMouseEventHandler().getNormY()
    const pickingRay = this.orbitCamera_.getPickingRay(fx, fy)
    const isLeftMouseDown = this.getMouseEventHandler().isDown(MouseButtons.LeftButton)

    const prevHoverState = this.gizmo_.isHovering()
    const prevDragState = this.gizmo_.isDragging()

    if (this.cameraActiveCounter_ === 0) {
      this.gizmo_.update(pickingRay, [fx, fy], isLeftMouseDown)
    }

    if (this.gizmo_.isHovering() !== prevHoverState || this.gizmo_.isDragging() !== prevDragState) {
      isUpdated = true
      this.scheduleRender_ = true
    }

    if (this.gizmo_.isDragging()) {
      isUpdated = true
      this.scheduleRender_ = true
    } else {
      if (this.orbitCamera_.update(deltaTime, this.getMouseEventHandler(), this.getKeyboardEventHandler(), this.getTouchEventHandler())) {
        this.cameraActiveCounter_ = 1
        isUpdated = true
        this.scheduleRender_ = true
      } else {
        this.cameraActiveCounter_ = 0
      }
    }

    this.getMouseEventHandler().flush()
    this.getTouchEventHandler().flush()
    return isUpdated
  }

  render () {
    this.updateFps()

    const vp = this.orbitCamera_.getViewProjection()
    const gl = this.gl

    const mvp = mat4.create()
    const mvpprim = mat4.create()

    gl.clearColor(0.15, 0.15, 0.15, 1.0)

    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    gl.enable(gl.DEPTH_TEST)

    gl.viewport(0, 0, this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight())

    mat4.multiply(mvpprim, vp, this.primTransform_)
    this.primRender_.clearPrimitive()
    this.primRender_.addGizmo(this.gizmo_)
    this.primRender_.render(mvpprim)

    if (this.vertexBuffer_) {
      const totalTransform = mat4.create()
      mat4.multiply(totalTransform, this.gizmo_.getOrientation(), this.transform_)
      mat4.multiply(mvp, vp, totalTransform)

      // Main render to canvas
      this.shader_.bindProgram()

      if (this.colorTexture_.getTextureHandle()) {
        this.colorTexture_.setActiveTexture(gl.TEXTURE0)
        this.colorTexture_.bind()
        this.shader_.setTextureUniform('colorTexture', 0)
      }
      if (this.combinedLabelTexture_.getTextureHandle()) {
        this.combinedLabelTexture_.setActiveTexture(gl.TEXTURE2)
        this.combinedLabelTexture_.bind()
        this.shader_.setTextureUniform('labelTexture', 2)
      }

      this.shader_.setUniformMat4fv('VP', mvp)
      this.shader_.setUniform1f('opacity', this.damageOpacity_)
      this.shader_.setUniform1i('highlightedLabel', this.hoveredLabel_)
      this.vertexBuffer_.draw(gl.TRIANGLES)
      this.shader_.unbindProgram()

      // Render ID to fbo. Should be possible to do in a single draw with
      // multiple render targets.
      this.readBackFbo_.bind()
      gl.clearColor(0.0, 0.0, 0.0, 0.0)
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)

      this.idshader_.bindProgram()

      if (this.labelTexture_.getTextureHandle()) {
        this.labelTexture_.setActiveTexture(gl.TEXTURE1)
        this.labelTexture_.bind()
        this.idshader_.setTextureUniform('labelTexture', 1)
      }
      if (this.labelVisibiltyTexture_.getTextureHandle()) {
        this.labelVisibiltyTexture_.setActiveTexture(gl.TEXTURE2)
        this.labelVisibiltyTexture_.bind()
        this.idshader_.setTextureUniform('visibilityLookupTexture', 2)
      }

      this.idshader_.setUniformMat4fv('VP', mvp)
      this.idshader_.setUniform1i('visibleBitmask', this.visibleDamageCategoriesBitmask_)
      this.vertexBuffer_.draw(gl.TRIANGLES)
      this.idshader_.unbindProgram()
      this.vertexBuffer_.unbind()
      this.readBackFbo_.unbind()
    }
  }

  /**
   * Mouse click handler.
   * @param {Object} ev mouseevent
   */
  getDamageForMousePosition (ev) {
    const gl = this.glContext_.gl()

    this.readBackFbo_.bind()
    const pixels = new Uint8Array(4)
    gl.readPixels(ev.offsetX, gl.drawingBufferHeight - 1 - ev.offsetY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels)
    this.readBackFbo_.unbind()

    let selectedObject = 0

    // pixels[3] == 0 : No damage
    const damageIndex = pixels[3] - 1
    if (pixels[3] > 0 && damageIndex < damageCategoriesShort.length && (this.visibleDamageCategories_.indexOf(damageCategoriesShort[damageIndex]) !== -1)) {
      this.pickingInfo_.object = damageCategoriesShort[damageIndex]
      selectedObject = pixels[0] + (pixels[1] << 8) + (pixels[2] << 16)
    } else {
      this.pickingInfo_.object = 'Unknown object'
      selectedObject = -1
    }
    return selectedObject
  }

  /**
   * Mouse click handler.
   * @param {Object} ev mouseevent
   */
  onClick (ev) {
    this.pickPosition(ev)
    const gl = this.glContext_.gl()

    this.readBackFbo_.bind()
    const pixels = new Uint8Array(4)
    gl.readPixels(ev.offsetX, gl.drawingBufferHeight - 1 - ev.offsetY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels)
    gl.readPixels(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight, gl.RGBA, gl.UNSIGNED_BYTE, this.readbackPixels_)
    this.readBackFbo_.unbind()

    this.selectedObject_ = 0

    // pixels[3] == 0 : No damage
    const damageIndex = pixels[3] - 1
    if (pixels[3] > 0 && damageIndex < damageCategoriesShort.length && (this.visibleDamageCategories_.indexOf(damageCategoriesShort[damageIndex]) !== -1)) {
      this.pickingInfo_.object = damageCategoriesShort[damageIndex]
      this.selectedObject_ = pixels[0] + (pixels[1] << 8) + (pixels[2] << 16)
    } else {
      this.pickingInfo_.object = 'Unknown object'
      this.selectedObject_ = -1
    }

    // Event handlers.
    if (ev.button === 0 && this.onLeftClickCallback) {
      this.onLeftClickCallback({ type: this.pickingInfo_.object, id: this.selectedObject_, imagedata: this.readbackPixels_ })
    } else if (ev.button === 2 && this.onRightClickCallback) {
      this.onRightClickCallback({ id: this.selectedObject_ })
    }
  }

  /**
   * Mouse move handler
   */
  onMouseMove (ev) {
    const labelValue = this.getDamageForMousePosition(ev)
    if (labelValue !== this.hoveredLabel_) {
      this.hoveredLabel_ = labelValue
      this.scheduleRender_ = true
      if (this.onDamageHoverCallback_) {
        this.onDamageHoverCallback_({ id: labelValue })
      }
    }
  }

  updateFps () {
    this.frameCount_++
    const timeSinceLastFpsMeasure = (performance.now() - this.lastFps_) / 1000.0
    if (timeSinceLastFpsMeasure > 1.0) {
      const fps = this.frameCount_ / timeSinceLastFpsMeasure
      this.lastFps_ = performance.now()
      this.frameCount_ = 0
      this.appInfo_.fps = Math.round(fps)
    }
  }

  /**
   * Resize the canvas
   * @param {number} width new canvas width
   * @param {height} height new canvas height
   */
  resize (width, height) {
    this.glContext_.canvas_.width = width
    this.glContext_.canvas_.height = height
    // Update camera projection matrix with new aspect
    this.orbitCamera_.setViewport(this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight())
    this.orbitCamera_.setPerspective(this.orbitCamera_.getFov())
    this.glContext_.gl_.viewport(0, 0, width, height)

    // Resize FBOs
    this.readBackFbo_.resize(width, height)
    this.posFbo_.resize(width, height)

    this.readbackPixels_ = new Uint8Array(width * height * 4)

    this.scheduleRender_ = true
  }

  /**
   * Get position of the current mouse position on the mesh before any
   * transformations, i.e. in the opensfm frame of reference.
   * @returns {vec3} position coordinates
   */
  pickPosition () {
    const gl = this.gl
    // let ray = this.orbitCamera_.getPickingRay(this.mouseEventHander_.getNormX(), this.mouseEventHander_.getNormY())
    // console.log(ray)
    const mvp = mat4.create()
    const totalTransform = mat4.create()
    mat4.multiply(totalTransform, this.gizmo_.getOrientation(), this.transform_)
    mat4.multiply(mvp, this.orbitCamera_.getViewProjection(), totalTransform)

    // Render ID to fbo
    this.posFbo_.bind()
    gl.clearBufferuiv(gl.COLOR, 0, new Uint32Array([0, 0, 0, 0]))
    gl.clear(gl.DEPTH_BUFFER_BIT)
    gl.viewport(0, 0, this.glContext_.getCanvasWidth(), this.glContext_.getCanvasHeight())

    this.posDepthShader_.bindProgram()

    this.posDepthShader_.setUniformMat4fv('VP', mvp)
    this.vertexBuffer_.bind()
    this.vertexBuffer_.draw(gl.TRIANGLES)
    this.posDepthShader_.unbindProgram()
    this.vertexBuffer_.unbind()

    const pixelsPos = new Uint32Array(4)

    gl.readPixels(this.mouseEventHander_.clientX_, gl.drawingBufferHeight - 1 - this.mouseEventHander_.clientY_, 1, 1, gl.RGBA_INTEGER, gl.UNSIGNED_INT, pixelsPos)
    this.readBackFbo_.unbind()
    const posDepth = new Float32Array(pixelsPos.buffer) // Raw untransformed position

    return vec3.fromValues(posDepth[0], posDepth[1], posDepth[2])
  }

  /* Compute world view projection matrix
   * @param {Object} cameraFeature
   * @returns {mat4} WVP matrix
   */
  computeWVPFromCamera (cameraFeature) {
    // Find camera parameters
    const instrument = cameraFeature.acquisitioninfo.instrument
    const origin = instrument.georeference.perspectivecentre.coordinates
    const rot = instrument.georeference.exteriororientation

    const cameraMatrixWorld = mat4.create()
    const cameraProj = mat4.create()

    mat4.perspective(cameraProj, 1.0, 1.0, 0.1, 1000.0)
    cameraProj[0] = 2 * instrument.focallength / (instrument.pixelspacing * instrument.imagecolumns)
    cameraProj[5] = -2 * instrument.focallength / (instrument.pixelspacing * instrument.imagerows)

    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        cameraMatrixWorld[4 * i + j] = rot[3 * i + j]
      }
    }

    cameraMatrixWorld[12] = origin[0]
    cameraMatrixWorld[13] = origin[1]
    cameraMatrixWorld[14] = origin[2]

    // Change to from OpenCV to OpenGL convention
    const permute = mat4.fromValues(
      1, 0, 0, 0,
      0, -1, 0, 0,
      0, 0, -1, 0,
      0, 0, 0, 1)

    mat4.multiply(cameraMatrixWorld, cameraMatrixWorld, permute)

    const invObliqueCameraMatrixWorld = mat4.create()
    mat4.invert(invObliqueCameraMatrixWorld, cameraMatrixWorld)

    // If the mesh has been transformed to Y up, the following camera
    // transformation is necessary to go back to the opensfm frame of reference.
    // const toZup = mat4.create()
    // mat4.fromXRotation(toZup, Math.PI / 2)
    // mat4.multiply(invObliqueCameraMatrixWorld, invObliqueCameraMatrixWorld, toZup)

    // Model view projection for mesh
    const cameraMVP = mat4.create()

    mat4.multiply(cameraMVP, cameraProj, invObliqueCameraMatrixWorld)

    return cameraMVP
  }

  /**
   * Create or update an annotation from a mask drawn on top of an image
   * @param {string} cameraFeature Camerafeature corresponding to the image
   * @param {number|null} annotationId Id of the annotation to update. Create a new annotation if annotationId is null
   * @param {number} damageCategoryIndex index of a damage category
   * @param {ImageData} overlayData Instance of ImageData that contains the mask image
   * @param {() => void} onComplete Callback when update is complete
   * @returns {number} Id of the updated or created annotation
   */
  createOrUpdateAnnotation (cameraFeature, annotationId, damageCategoryIndex, overlayData, onComplete = () => {}) {
    if (damageCategoryIndex < 0 || damageCategoryIndex >= this.labelmapDamageCategoriesData_.length) {
      return false
    }
    const gl = this.gl
    const imageData = this.renderImageOverlayOnMesh(cameraFeature, overlayData)
    const width = imageData.width
    const height = imageData.height
    const pixeldataU8 = imageData.data

    const labelmap = this.labelmapDamageCategoriesData_[damageCategoryIndex]
    const labelmapWidth = labelmap.width
    const labelmapHeight = labelmap.height

    let updateId = annotationId
    if (annotationId === null) {
      updateId = labelmap.nextAnnotationId
      labelmap.nextAnnotationId++
    } else {
      // Clear existing pixels of the same label value
      for (let i = 0; i < labelmapHeight * labelmapWidth; i++) {
        if (labelmap.pixeldata[i] === updateId) {
          labelmap.pixeldata[i] = 0
        }
      }
    }
    for (let i = 0; i < width * height; i++) {
      const u = (pixeldataU8[4 * i] * 256 + pixeldataU8[4 * i + 1]) / 65535.0
      const v = (pixeldataU8[4 * i + 2] * 256 + pixeldataU8[4 * i + 3]) / 65535.0
      const px = Math.round(u * labelmapWidth)
      const py = Math.round(v * labelmapHeight)

      // TODO: 0,0 is a valid value. Nodata mask should be handled better.
      if (u !== 0.0 || v !== 0.0) {
        labelmap.pixeldata[py * labelmapWidth + px] = updateId
      }
    }

    // Update textures
    const texData = new Uint8Array(labelmap.pixeldata.buffer, labelmap.pixeldata.byteOffset)
    this.labelTexture_.bind()
    gl.texSubImage3D(gl.TEXTURE_2D_ARRAY, 0, 0, 0, damageCategoryIndex, labelmapWidth, labelmapHeight, 1, gl.RG_INTEGER, gl.UNSIGNED_BYTE, texData)
    this.labelTexture_.unbind()
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)

    this.scheduleRender_ = true

    onComplete()

    return updateId
  }

  /**
   * Render a binary mask as uv coordinates on a mesh
   * @param {Object} cameraFeature
   * @param {ImageData} overlayData ImageData containing pixelvalues of the mask
   * @returns {ImageData} Texturecoordinates of the masked pixels encoded in RGBA8 values
   */
  renderImageOverlayOnMesh (cameraFeature, overlayData) {
    const gl = this.gl
    const cameraMVP = this.computeWVPFromCamera(cameraFeature)
    const instrument = cameraFeature.acquisitioninfo.instrument
    const width = instrument.imagecolumns / 4
    // Rounding down has been applied to support more resolutions
    // it may cause the overlay to be "moved"
    const height = Math.floor(instrument.imagerows / 4)

    this.imageAnnotationTexture_.setActiveTexture(gl.TEXTURE3)
    if (!this.imageAnnotationTexture_.getTextureHandle()) {
      this.imageAnnotationTexture_.create2D(gl.RGBA8, overlayData.width, overlayData.height, true, false)
    } else {
      this.imageAnnotationTexture_.resize2D(overlayData.width, overlayData.height)
    }
    this.imageAnnotationTexture_.uploadDataToTexture(gl.RGBA, gl.UNSIGNED_BYTE, overlayData.data)

    this.imageOverlayFbo_.resize(width, height)

    this.imageOverlayFbo_.bind()

    gl.clearColor(0, 0, 0, 0)
    gl.viewport(0, 0, width, height)
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)

    this.meshUvMaskShader_.bindProgram()

    this.imageAnnotationTexture_.setActiveTexture(gl.TEXTURE3)
    this.imageAnnotationTexture_.bind()
    this.meshUvMaskShader_.setTextureUniform('maskTexture', 3)
    this.meshUvMaskShader_.setUniform2f('viewport', width, height)
    this.meshUvMaskShader_.setUniformMat4fv('VP', cameraMVP)

    this.vertexBuffer_.draw(gl.TRIANGLES)
    this.meshUvMaskShader_.unbindProgram()

    // const pixeldata = new Uint16Array(4 * width * height)
    const pixeldataU8 = new Uint8ClampedArray(4 * width * height)
    // Data is packed as :
    // u[i] = (pixeldataU8[4*i] * 256 + pixeldataU8[4*i+1]) / 65535
    // v[i] = (pixeldataU8[4*i+2] * 256 + pixeldataU8[4*i+3]) / 65535
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixeldataU8)

    this.imageOverlayFbo_.unbind()
    this.imageAnnotationTexture_.unbind()

    const imageData = new ImageData(pixeldataU8, width, height)
    return imageData
  }

  /* Render labelmap from camera corresponding to imageName
   * @param {string} imageName Image filename
   * @param {number} damageCategoryIndex Index of the damage in the damamageCategoriesShortString
   * @param {number} [annotationId] Index of a specific label to render. If undefined, render all annotations
   * @returns {ImageData} RGBA pixelvalues of the projected mask image
   */
  renderLabelmapFromCameraImagename (imageName, damageCategoryIndex, annotationId) {
    const feature = this.cameraFeatures_.find(e => { return (e.references[0].url.match(imageName) != null) })
    if (!feature) {
      return false
    }
    return this.renderLabelmapFromCameraFeature(feature, damageCategoryIndex, annotationId)
  }

  /* Render labelmap from camera corresponding to imageId index in this.cameraFeatures_
   * @param {number} imageId Index in this.cameraFeatures_
   * @param {number} damageCategoryIndex Index of the damage in the damamageCategoriesShortString
   * @param {number} [annotationId] Index of a specific label to render. If undefined, render all annotations
   * @returns {ImageData} RGBA pixelvalues of the projected mask image
   */
  renderLabelmapFromCameraIndex (imageId, damageCategoryIndex, annotationId) {
    if (imageId < this.cameraFeatures_.length) {
      const feature = this.cameraFeatures_[imageId]
      return this.renderLabelmapFromCameraFeature(feature, damageCategoryIndex, annotationId)
    }
  }

  /* Render labelmap from camera feature
   * @param {Object} cameraFeature Camerafeature defining the model-view-projection
   * @param {number} damageCategoryIndex Index of the damage in the damamageCategoriesShortString
   * @param {number} [annotationId] Index of a specific label to render. If undefined, render all annotations.
   * @returns {ImageData} RGBA pixelvalues of the projected mask image
   */
  renderLabelmapFromCameraFeature (cameraFeature, damageCategoryIndex, annotationId) {
    const gl = this.gl
    const cameraMVP = this.computeWVPFromCamera(cameraFeature)

    const instrument = cameraFeature.acquisitioninfo.instrument
    const width = Math.round(instrument.imagecolumns / 8)
    const height = Math.round(instrument.imagerows / 8)
    this.maskFbo_.resize(width, height)
    this.maskFbo_.bind()

    gl.clearColor(0, 0, 0, 0)
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    gl.viewport(0, 0, width, height)
    this.maskShader_.bindProgram()
    if (this.labelTexture_.getTextureHandle()) {
      this.labelTexture_.setActiveTexture(gl.TEXTURE1)
      this.labelTexture_.bind()
      this.maskShader_.setTextureUniform('labelTexture', 1)
    }

    this.maskShader_.setUniform1i('labelType', damageCategoryIndex)
    if (annotationId) {
      this.maskShader_.setUniform1i('selectedAnnotationId', annotationId)
    } else {
      this.maskShader_.setUniform1i('selectedAnnotationId', 0)
    }
    this.maskShader_.setUniformMat4fv('VP', cameraMVP)
    this.vertexBuffer_.draw(gl.TRIANGLES)
    this.maskShader_.unbindProgram()

    const pixeldata = new Uint8ClampedArray(4 * width * height)
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixeldata)
    const imageData = new ImageData(pixeldata, width, height)
    this.maskFbo_.unbind()
    return imageData
  }

  /* Get a list of images that contain annotationId for a damageCategory
   * @return array of strings
   * @param {Object[]} cameraFeatures Array of camerafeatures to filter
   * @param {number} damageCategoryIndex Index of the damage in the damamageCategoriesShortString
   * @param {number} annotationId Index of a specific label to render
   * @param {number} [requestedWidth=64] Width of framebuffer, default 64
   * @param {number} [requestedHeight=64] Height of framebuffer, default 64
   */
  getAnnotaionImagesFromCameraFeatures (cameraFeatures, damageCategoryIndex, annotationId, requestedWidth, requestedHeight) {
    // Setup common gl state

    const gl = this.gl
    let width = 64
    let height = 64
    if (requestedWidth !== undefined) {
      width = requestedWidth
    }
    if (requestedHeight !== undefined) {
      height = requestedHeight
    }

    const pixeldata = new Uint8ClampedArray(4 * width * height)

    this.maskFbo_.resize(width, height)
    this.maskFbo_.bind()

    gl.clearColor(0, 0, 0, 0)
    gl.viewport(0, 0, width, height)
    this.maskShader_.bindProgram()
    if (this.labelTexture_.getTextureHandle()) {
      this.labelTexture_.setActiveTexture(gl.TEXTURE1)
      this.labelTexture_.bind()
      this.maskShader_.setTextureUniform('labelTexture', 1)
    }

    this.maskShader_.setUniform1i('labelType', damageCategoryIndex)
    this.maskShader_.setUniform1i('selectedAnnotationId', annotationId)

    const filteredFeatureNames = []
    for (let i = 0; i < cameraFeatures.length; i++) {
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
      const cameraMVP = this.computeWVPFromCamera(cameraFeatures[i])
      this.maskShader_.setUniformMat4fv('VP', cameraMVP)
      this.vertexBuffer_.draw(gl.TRIANGLES)
      gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixeldata)

      if (pixeldata.indexOf(255) !== -1) {
        let pixelCount = 0
        for (let j = 0; j < width * height; j++) {
          if (pixeldata[4 * j] !== 0) {
            pixelCount++
          }
        }
        filteredFeatureNames.push({ imageid: cameraFeatures[i].references[0].url, pixelCount: pixelCount })
      }
    }
    this.maskShader_.unbindProgram()

    this.maskFbo_.unbind()

    filteredFeatureNames.sort(function (a, b) { return (b.pixelCount - a.pixelCount) })

    const sortedFeatureNames = []
    filteredFeatureNames.forEach((feature) => {
      sortedFeatureNames.push(feature.imageid)
    })

    return sortedFeatureNames
  }

  /**
   * Update combined label texture
   * @param {string[]} visibleCategories Shortstring containing the categories to show
   */
  updateCombinedOverlayTexture (visibleCategories /* String array */) {
    const gl = this.gl

    const width = this.labelTexture_.getWidth()
    const height = this.labelTexture_.getHeight()

    this.visibleDamageCategoriesBitmask_ = 0
    for (let i = 0; i < visibleCategories.length; i++) {
      const catIndex = damageCategoriesShort.indexOf(visibleCategories[i])
      if (catIndex >= 0) {
        this.visibleDamageCategoriesBitmask_ |= (1 << catIndex)
      }
    }

    if (width === 0 && height === 0) {
      return
    }

    this.combinedOverlayFbo_.resize(width, height)
    this.combinedOverlayFbo_.bind()

    gl.viewport(0, 0, width, height)

    gl.clearColor(0, 0, 0, 0)
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    this.combineOverlayShader_.bindProgram()

    if (this.labelTexture_.getTextureHandle()) {
      this.labelTexture_.setActiveTexture(gl.TEXTURE1)
      this.labelTexture_.bind()
      this.combineOverlayShader_.setTextureUniform('labelTexture', 1)
    }
    if (this.labelVisibiltyTexture_.getTextureHandle()) {
      this.labelVisibiltyTexture_.setActiveTexture(gl.TEXTURE2)
      this.labelVisibiltyTexture_.bind()
      this.combineOverlayShader_.setTextureUniform('visibilityLookupTexture', 2)
    }

    this.combineOverlayShader_.setUniform1i('visibleBitmask', this.visibleDamageCategoriesBitmask_)
    this.quadVbo_.draw(gl.TRIANGLES)

    this.combineOverlayShader_.unbindProgram()
    this.combinedOverlayFbo_.unbind()

    this.scheduleRender_ = true
  }

  /**
   * Debug visualization
   * @param {string} imageId The ID or file name of the image to create overlay for
   * @param {string} damageCategoryString Short string of a damage category
   */
  createDebugCanvas (imageData, imageId) {
    const canvas = document.createElement('canvas')
    canvas.width = imageData.width
    canvas.height = imageData.height
    const context = canvas.getContext('2d')

    context.putImageData(imageData, 0, 0)
    const pngdata = canvas.toDataURL('image/png')

    canvas.style.background = 'black'
    this._dataModel.imageById(imageId).then((imageDataUrl) => {
      const image = new Image()
      image.onload = () => {
        context.globalCompositeOperation = 'source-over'
        context.globalAlpha = 0.5
        context.drawImage(image, 0, 0, canvas.width, canvas.height)
        document.body.append(canvas)
      }
      image.src = imageDataUrl
    })
    return pngdata
  }

  // WgAppInterface API
  //

  /**
   * Set left click handler.
   * @param {function} callback The callback function to be invoked on left
   * clicks. This method must be given an object with the id of the polygon
   * clicked (set to -1 if no polygon is clicked) and the type of damage
   * the polygon represents according to the ML model.
   */
  setLeftClickHandler (callback) {
    this.onLeftClickCallback = callback
  }

  /**
   * Set right click handler.
   * @param {function} callback The callback function to be invoked on right
   * clicks. This method must be given an object with the id of the polygon
   * clicked (-1 if no polygon is clicked).
   */
  setRightClickHandler (callback) {
    this.onRightClickCallback = callback
  }

  /**
   * Set damage hover callback
   * @param {function} callback The callback function to be invoked on mouse entering or leaving a damage.
   * This method must be given an object with the id of the polygon
   * (set to -1 if no polygon is highlighted)
   */
  setDamageHoverCallback (callback) {
    this.onDamageHoverCallback_ = callback
  }

  /**
   * @param {number} annotationId The ID of the annotation to create overlay for
   * @param {string} damageCategoryString Short-string of a damage category
   * @returns {string[]} imageIds that contain annotationId
   */
  getAnnotationImages (annotationId, damageCategoryString) {
    const damageCategoryId = damageCategoriesShort.indexOf(damageCategoryString)
    // Return a list of images
    return this.getAnnotaionImagesFromCameraFeatures(this.cameraFeatures_, damageCategoryId, annotationId)
  }

  /*
   * Get images that contain the pointed pixel in the current mouse state
   * @returns {string[]} imageIds that contain annotationId
   */
  getImagesForNewAnnotation () {
    // Find the position of the mouse on the mesh
    const pos = this.pickPosition()

    // Approximate the normal by the view vector
    // Transform the camera position to raw mesh frame
    const totalTransform = mat4.create()
    mat4.multiply(totalTransform, this.gizmo_.getOrientation(), this.transform_)

    const invTransform = mat4.create()
    const cameraPos = this.orbitCamera_.getPosition()
    const cameraPosMeshFrame = vec4.create()

    mat4.invert(invTransform, totalTransform)

    vec4.transformMat4(cameraPosMeshFrame, [cameraPos[0], cameraPos[1], cameraPos[2], 1.0], invTransform)

    vec4.scale(cameraPosMeshFrame, cameraPosMeshFrame, 1.0 / cameraPosMeshFrame[3])
    const approxNormal = vec3.create()
    vec3.sub(approxNormal, cameraPosMeshFrame, pos)
    vec3.normalize(approxNormal, approxNormal)

    // Find images where the picked point is in the view frustum and the
    // approximated normal points towards the camera.
    const filteredFeatureNames = []
    // Filter features
    this.cameraFeatures_.forEach((cameraFeature) => {
      const ndc = vec4.create()
      const ndcDisplaced = vec4.create()
      const wvp = this.computeWVPFromCamera(cameraFeature)

      vec4.transformMat4(ndc, [pos[0], pos[1], pos[2], 1.0], wvp)
      vec4.scale(ndc, ndc, 1.0 / ndc[3])
      const depth = ndc[2]

      // Offset a point along the normal. This should probably be scaled
      // by the scene size
      vec4.transformMat4(ndcDisplaced, [pos[0] + approxNormal[0], pos[1] + approxNormal[1], pos[2] + approxNormal[2], 1.0], wvp)
      vec4.scale(ndcDisplaced, ndcDisplaced, 1.0 / ndcDisplaced[3])
      const depthDisplaced = ndcDisplaced[2]

      if (depthDisplaced < depth && Math.abs(ndc[0]) < 0.8 && Math.abs(ndc[1]) < 0.8) {
        filteredFeatureNames.push({ imageid: cameraFeature.references[0].url, depthDiff: depth - depthDisplaced })
      }
    })
    // Sort by incidence angle
    filteredFeatureNames.sort(function (a, b) { return (b.depthDiff - a.depthDiff) })

    const sortedFeatureNames = []
    filteredFeatureNames.forEach((feature) => {
      sortedFeatureNames.push(feature.imageid)
    })

    return sortedFeatureNames
  }

  /**
   * @param {number} annotationId The ID of the annotation to create overlay for
   * @param {string} imageId The ID or file name of the image to create overlay for
   * @param {string} damageCategoryString Short-string of a damage category
   * @returns {ImageData} Instance of RGBA pixelvalues of the overlay
   */
  getAnnotationOverlay (annotationId, imageId, damageCategoryString) {
    const damageCategoryId = damageCategoriesShort.indexOf(damageCategoryString)
    if (damageCategoryId === -1) {
      throw new Error('Unknown damageCategory' + damageCategoryString)
    }
    const imageData = this.renderLabelmapFromCameraImagename(imageId, damageCategoryId, annotationId)
    return imageData
  }

  /**
   * Get an imagedata overlay
   * @param {string} imageName The file name of the image to create overlay for
   * @param {string} damageCategoryString Short-string of a damage category
   * @returns {ImageData} RGBA pixelvalues of the overlay
   */
  getDamageCategoryOverlay (imageName, damageCategoryString) {
    const damageCategoryId = damageCategoriesShort.indexOf(damageCategoryString)
    if (damageCategoryId === -1) {
      throw new Error('Unknown damageCategory' + damageCategoryString)
    }
    const imageData = this.renderLabelmapFromCameraImagename(imageName, damageCategoryId)
    return imageData
  }

  /**
   * Update an annotation from a mask drawn on top of an image
   * @param {string} imageName The file name of the image
   * @param {number} annotationId Id of the annotation to update
   * @param {string} damageCategoryString Short-string of a damage category
   * @param {ImageData} overlayData Instance of ImageData that contains the mask image
   * @param {() => void} onComplete Callback when update is complete
   */
  updateAnnotationInImage (imageName, annotationId, damageCategoryString, overlayData, onComplete = () => {}) {
    // Update labelmap
    const feature = this.cameraFeatures_.find(e => { return (e.references[0].url.match(imageName) != null) })
    const damageCategoryId = damageCategoriesShort.indexOf(damageCategoryString)
    this.createOrUpdateAnnotation(feature, annotationId, damageCategoryId, overlayData, onComplete)
  }

  /**
   * Create an annotation from a mask drawn on top of an image
   * @param {string} imageName The file name of the image
   * @param {string} damageCategoryString Short-string of a damage category
   * @param {ImageData} overlayData Instance of ImageData that contains the mask image
   * @param {() => void} onComplete Callback when creation is complete
   * @return {number} The Id of the new annotation
   */
  createAnnotation (imageName, damageCategoryString, overlayData, onComplete = () => {}) {
    // Update labelmap
    const feature = this.cameraFeatures_.find(e => { return (e.references[0].url.match(imageName) != null) })
    const damageCategoryId = damageCategoriesShort.indexOf(damageCategoryString)
    return this.createOrUpdateAnnotation(feature, null, damageCategoryId, overlayData, onComplete)
  }

  /**
   * Delete an annotation
   * @param {number} annotationId Id of the annotation to delete
   * @param {string} damageCategoryString Short-string of a damage category
   */
  deleteAnnotation (annotationId, damageCategoryString) {
    const gl = this.gl
    const damageCategoryIndex = damageCategoriesShort.indexOf(damageCategoryString)
    if (damageCategoryIndex === -1) {
      throw new Error('Unknown damageCategory' + damageCategoryString)
    }

    const labelmap = this.labelmapDamageCategoriesData_[damageCategoryIndex]
    const labelmapWidth = labelmap.width
    const labelmapHeight = labelmap.height

    let updateTexture = false
    // Clear existing pixels of the annotation value
    for (let i = 0; i < labelmapHeight * labelmapWidth; i++) {
      if (labelmap.pixeldata[i] === annotationId) {
        labelmap.pixeldata[i] = 0
        updateTexture = true
      }
    }

    if (updateTexture) {
      // Update textures
      const texData = new Uint8Array(labelmap.pixeldata.buffer, labelmap.pixeldata.byteOffset)
      this.labelTexture_.bind()
      gl.texSubImage3D(gl.TEXTURE_2D_ARRAY, 0, 0, 0, damageCategoryIndex, labelmapWidth, labelmapHeight, 1, gl.RG_INTEGER, gl.UNSIGNED_BYTE, texData)
      this.labelTexture_.unbind()
      this.updateCombinedOverlayTexture(this.visibleDamageCategories_)
    }
  }

  /**
   * Get annotation IDs
   * @return {Array} Array of structs that contain damage category name and the corresponding list of annotation IDs
   */
  getAnnotationIds () {
    const annotationsList = []
    for (let i = 0; i < damageCategoriesShort.length; i++) {
      const labelmap = this.labelmapDamageCategoriesData_[i]
      const unique = new Set(labelmap.pixeldata)
      // Remove the clear value from the unique labels
      unique.delete(0)
      annotationsList.push({ damageCategory: damageCategoriesShort[i], annotationIds: [...unique] })
    }
    return annotationsList
  }

  /**
   * Get total number of annotations for all damage categories
   * @return {number} Annotation count
   */
  getTotalAnnotationCount () {
    let count = 0
    this.labelmapDamageCategoriesData_.forEach((labelmap) => {
      const unique = new Set(labelmap.pixeldata)
      // Remove the clear value from the unique labels
      unique.delete(0)
      count += unique.size
    })
    return count
  }

  /**
   * Get damage opacity
   * @return {number} opacity
   */
  getDamageOpacity () {
    return this.damageOpacity_
  }

  /**
   * Set damage opacity
   * @param {number} opacity in the range [0,1]
   */
  setDamageOpacity (opacity) {
    this.damageOpacity_ = opacity
    this.scheduleRender_ = true
  }

  /**
   * Set visible damage categories
   * @param {string[]} visibleCategories Short-strings of the damage categories to show
   */
  setDamageCategoryVisibility (visibleCategories) {
    this.visibleDamageCategories_ = visibleCategories
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)
    this.scheduleRender_ = true
  }

  /**
   * Get visible damage categories
   * @returns {string[]} visibleCategories short-strings of the damage categories shown
   */
  getDamageCategoryVisibility () {
    return this.visibleDamageCategories_
  }

  /**
   * Get labelmap for a damage category
   * @param {string} damageCategoryString Short-string of a damage category
   * @return {ArrayBuffer} Bytedata for the labelmap in npy format
   */
  getUVLabelmap (damageCategoryString) {
    const damageCategoryId = damageCategoriesShort.indexOf(damageCategoryString)
    if (damageCategoryId === -1) {
      throw new Error('Unknown damageCategory' + damageCategoryString)
    }

    return this.labelmapDamageCategoriesData_[damageCategoryId].npyData
  }

  /**
   * Set visibility of rotation gizmo
   * @param {bool} showGizmo New gizmo visibility state
   */
  setGizmoVisibility (showGizmo) {
    if (showGizmo === true) {
      this.gizmo_.setGizmoMode(wGizmoMode.ROTATE)
    } else {
      this.gizmo_.setGizmoMode(wGizmoMode.INACTIVE)
    }
    this.scheduleRender_ = true
  }

  /**
   * Get visibility of rotation gizmo
   * @return {bool} Gizmo visibility state
   */
  getGizmoVisibility () {
    return this.gizmo_.getGizmoMode() !== wGizmoMode.INACTIVE
  }

  /**
   * Hide a specific set of annotations
   * Overrides all previous calls to showAnnotations and hideAnnotations.
   * @param {number[]} annotationIds Array of ids to hide. Entries must be in [0,65535]
   */
  hideAnnotations (annotationIds) {
    const gl = this.gl
    // Set all labels visible
    const visibleLabelsData = new Uint8Array(65536).fill(255)
    // Hide the subset
    for (let i = 0; i < annotationIds.length; i++) {
      const id = parseInt(annotationIds[i])
      if (id >= visibleLabelsData.length) {
        throw new Error('Annotation id out of range')
      }
      visibleLabelsData[id] = 0
    }
    this.labelVisibiltyTexture_.uploadDataToTexture(gl.RED, gl.UNSIGNED_BYTE, visibleLabelsData)

    // Update the combined texture
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)
  }

  /**
   * Hide all annotations
   */
  hideAllAnnotations () {
    const gl = this.gl
    // Set all invisible
    const visibleLabelsData = new Uint8Array(65536)
    this.labelVisibiltyTexture_.uploadDataToTexture(gl.RED, gl.UNSIGNED_BYTE, visibleLabelsData)

    // Update the combined texture
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)
  }

  /**
   * Show only a specific set of annotations.
   * Overrides all previous call to showAnnotations and hideAnnotations.
   * @param {number[]} annotationIds Array of ids to show. Entries must be in [0,65535]
   */
  showAnnotations (annotationIds) {
    const gl = this.gl
    // Set all labels visible
    const visibleLabelsData = new Uint8Array(65536)
    for (let i = 0; i < annotationIds.length; i++) {
      const id = parseInt(annotationIds[i])
      if (id >= visibleLabelsData.length) {
        throw new Error('Annotation id out of range')
      }
      visibleLabelsData[id] = 255
    }
    this.labelVisibiltyTexture_.uploadDataToTexture(gl.RED, gl.UNSIGNED_BYTE, visibleLabelsData)

    // Update the combined texture
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)
  }

  /**
   * Show all annotations
   */
  showAllAnnotations () {
    const gl = this.gl
    // Set all labels visible
    const visibleLabelsData = new Uint8Array(65536).fill(255)
    this.labelVisibiltyTexture_.uploadDataToTexture(gl.RED, gl.UNSIGNED_BYTE, visibleLabelsData)

    // Update the combined texture
    this.updateCombinedOverlayTexture(this.visibleDamageCategories_)
  }

  /**
   * Get label colours
   * @return {string[]} Colours in RGBA-format, e.g. '(1.0,0.0,1.0,1.0)'
   */
  getLabelColors () {
    return [
      '(1.0,0.0,1.0,1.0)',
      '(0.0,1.0,0.0,1.0)',
      '(0.0,0.0,1.0,1.0)',
      '(1.0,1.0,0.0,1.0)',
      '(1.0,0.0,0.0,1.0)'
    ]
  }
}
export { WgApp }
