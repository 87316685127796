import { IMsalContext } from '@azure/msal-react'
import { FC, ReactElement } from 'react'
import { Box } from '@material-ui/core'

const authLoading: FC<IMsalContext> = (props: IMsalContext): ReactElement => {
  return (
    <Box m={1}>Authentication in progress: {props.inProgress}</Box>
  )
}

export { authLoading }
