import { ServiceClient } from "src/datasource/ServiceClient"
import { UserServiceClient } from "src/datasource/UserServiceClient";
import { gzipSync } from 'fflate'

/*
 * Uses blob storage to fetch images 
 */
export class LabelMapServiceClient {
  private readonly client: ServiceClient
  private readonly userServiceClient: UserServiceClient

  constructor (client: ServiceClient, userServiceClient: UserServiceClient) {
    this.client = client;
    this.userServiceClient = userServiceClient
  }
  
  async getUrl (analysisId: number, damageShortName: string): Promise<string> {
    const tenantId = await this.getTenantId()
    return 'api/modelblob/analysis/' + analysisId + '/consensus/label/' + damageShortName + '/tenant/' + tenantId
  }

  async getZipUrl (analysisId: number, damageShortName: string): Promise<string> {
    const tenantId = await this.getTenantId()
    return 'api/modelblob/analysis/' + analysisId + '/consensus/ziplabel/' + damageShortName + '/tenant/' + tenantId
  }

  private async getTenantId (): Promise<number> {
    return this.userServiceClient.getCurrentTenantId()
  }

  public async postLabelMap(analysisId: number, damageShortName: string, body: ArrayBuffer): Promise<boolean> {
    const url = await this.getZipUrl(analysisId, damageShortName);   
    const zippedBody = gzipSync(new Uint8Array(body)).buffer
    return this.client.postBinary(url, zippedBody)
  }
}
