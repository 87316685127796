import { Bitmap } from './Bitmap'

/**
 * Type for describing a bounding box defined by its top left
 * and bottom right corners. The top left corner is (x1,y1)
 * and the bottom right corner is (x2,y2).
 */
interface BoundingBox {
  x1: number
  y1: number
  x2: number
  y2: number
}

enum ColorChannel {
  Red = 0,
  Green = 1,
  Blue = 2,
  Alpha = 3,
  All = 4
}

/**
 * An image with only two color values - 0 and 1.
 */
class BitmapImage {
  _width: number
  _height: number
  _bitmap: Bitmap

  /**
   * Constructor. This constructor dows sanity checking of its input.
   * The size of the bitmap must be width * height. If no bitmap is given,
   * a new empty bitmap is created.
   * @param width The width of the image.
   * @param height The height of the image.
   * @param bitmap The bitmap representing the image data.
   * @throws An Error if the dimensions of the images doesn't match
   * the given bitmap.
   */
  constructor (width: number, height: number, bitmap: Bitmap | undefined = undefined) {
    if (bitmap !== undefined && bitmap.size !== width * height) {
      throw new Error('Invalid image dimensions given.')
    }
    this._width = width
    this._height = height
    this._bitmap = bitmap !== undefined ? bitmap : new Bitmap(width * height)
  }

  getHeight(): number {
    return this._height
  }

  getWidth(): number {
    return this._width
  }

  get bitmap (): Bitmap { return this._bitmap }

  /**
   * Factory method for creating a BitmapImage from an array of RGBA data.
   * @param data RGBA data array.
   * @param width The width of the image.
   * @param height The height of the image.
   * @param channel The color channel to look at when setting the bits.
   * @returns A new BitmapImage instance.
   */
  static fromRGBAData (data: Uint8ClampedArray, width: number, height: number, channel: ColorChannel = ColorChannel.All): BitmapImage {
    // Sanity check.
    if (data.length % 4 !== 0) {
      throw new Error('The RGBA data array must be divisible by 4.')
    }
    if (data.length !== width * height * 4) {
      throw new Error('Invalid image dimensions given.')
    }

    const bitmap = new Bitmap(width * height)

    // Convert RGBA data to a pure bitmap.
    if (channel === ColorChannel.All) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== 0) {
          bitmap.set(Math.floor(i / 4))
        }
      }
    } else {
      for (let i = channel; i < data.length; i += 4) {
        if (data[i] !== 0) {
          bitmap.set(Math.floor(i / 4))
        }
      }
    }
    return new BitmapImage(width, height, bitmap)
  }

  /**
   * Write the bitmap image back to the RGBA format that it was
   * created from when using \see fromRGBAData.
   * @param channel The color channel to write to.
   * @returns An array of RGBA tuples representing an image.
   */
  toRGBAData (channel: ColorChannel = ColorChannel.All): Uint8ClampedArray {
    const data = new Uint8ClampedArray(this.bitmap.size * 4)
    const enabledIndices = this.bitmap.enabledIndices()
    enabledIndices.forEach(index => {
      const offset = index * 4
      if (channel === ColorChannel.All) {
        data[offset] = 255
        data[offset + 1] = 255
        data[offset + 2] = 255
      } else {
        data[offset + channel] = 255
      }
      data[offset + 3] = 255 // Alpha is set regardless to avoid confusion ;-)
    })
    return data
  }

  /**
   * Get the minimum bounding box of the data within the bitmap image.
   * @param margin The margin to add to the bounding box. This margin is
   * added to the top, bottom, left and right og the box.
   * @returns The bounding box of the data within the bitmap image.
   */
  boundingBox (margin: number = 0): BoundingBox {
    const result: BoundingBox = { x1: 0, y1: 0, x2: this._width - 1, y2: this._height - 1 }

    // The bitmap is laid out in row-by-row fashion, so running through it's
    // selected indices is running through the image data line by line from
    // left to right. The first and the last enabled index must then define
    // y1 and y2.
    const enabledIndices = this._bitmap.enabledIndices()
    result.y1 = Math.floor(enabledIndices[0] / this._width)
    result.y2 = Math.floor(enabledIndices[enabledIndices.length - 1] / this._width)

    let minX = this._width
    let maxX = -1
    for (let i = 0; i < enabledIndices.length; i++) {
      const xPosition = enabledIndices[i] - Math.floor(enabledIndices[i] / this._width) * this._width
      if (xPosition < minX) {
        minX = xPosition
      }
      if (xPosition > maxX) {
        maxX = xPosition
      }
    }
    result.x1 = minX
    result.x2 = maxX

    // Apply margin if needed.
    if (margin !== 0) {
      result.x1 = result.x1 - margin > 0 ? result.x1 - margin : 0
      result.y1 = result.y1 - margin > 0 ? result.y1 - margin : 0
      result.x2 = result.x2 + margin < this._width ? result.x2 + margin : this._width - 1
      result.y2 = result.y2 + margin < this._height ? result.y2 + margin : this._height - 1
    }

    return result
  }

  /**
   * Scale the bitmap image down by the given factor.
   * @param factor The factor to scale the bitmap image down by.
   * @throws An Error if the image cannot be scaled by that factor.
   */
  scaleDown (factor: number): void {
    // This scaling only works if the dimensions can be divided evenly
    // into 'factor' pieces.
    // We do not check height that matches the factor, as we want to allow
    // images of "weird" resolution
    if (this._width % factor !== 0) {
      throw new Error('Invalid scaling factor given.')
    }

    const result = new Bitmap(this._bitmap.size / (factor * factor))
    const resultWidth = this._width / factor
    // Rounding as this may not be an integer
    const resultHeight = Math.floor(this._height / factor)

    for (let x = 0; x < resultWidth; x++) {
      for (let y = 0; y < resultHeight; y++) {
        const upperLeftX = x * factor
        const upperLeftY = y * factor
        let isSet = false
        for (let x2 = upperLeftX; x2 < upperLeftX + factor; x2++) {
          for (let y2 = upperLeftY; y2 < upperLeftY + factor; y2++) {
            const positionInOrig = y2 * this._width + x2
            if (this._bitmap.isSet(positionInOrig)) {
              isSet = true
            }
          }
        }
        if (isSet) {
          result.set(y * resultWidth + x)
        }
      }
    }

    this._bitmap = result
    this._width = resultWidth
    this._height = resultHeight
  }

  /**
   * Scale up the size of the bitmap image.
   * @param factor Scale up factor.
   * @throws An Error if the factor is invalid.
   */
  scaleUp (factor: number): void {
    if (factor < 1) {
      throw new Error('Invalid factor given.')
    }
    factor = Math.floor(factor)

    const result = new Bitmap(this.bitmap.size * factor * factor)
    const newWidth = this._width * factor
    const enabledIndices = this.bitmap.enabledIndices()
    enabledIndices.forEach(index => {
      const row = Math.floor(index / this._width)
      const column = index % this._width
      const rowOffset = row * factor
      const columnOffset = column * factor
      for (let i = rowOffset; i < rowOffset + factor; i++) {
        for (let j = columnOffset; j < columnOffset + factor; j++) {
          result.set(i * newWidth + j)
        }
      }
    })

    this._bitmap = result
    this._height = this._height * factor
    this._width = this._width * factor
  }
}

export { BitmapImage, ColorChannel }
export type { BoundingBox }
