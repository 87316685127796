import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  selected: {
    boxSizing: 'border-box',
    border: 'solid 2px ' + theme.palette.primary.main,
  }
}))

export default useStyles
