import { FC, useState } from 'react'
import './App.css'
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { authLoading } from './AuthLoading'
import { authError } from './AuthError'
import { QueryClientProvider } from 'react-query'
import { Box } from '@material-ui/core'
import { Analyses } from 'src/components/Analyses'
import { Layout } from 'src/components/layout/Layout'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Inspections } from 'src/components/Inspections'
import { Analysis } from 'src/components/Analysis'
import { Report } from 'src/components/report/Report'
import { paths } from 'src/datamodel/Constants'
import { useApp } from 'src/components/AppContextProvider'

export interface RouterParams {
  inspectionId?: string | undefined
  analysisId?: string | undefined
}

const App: FC<void> = () => {
  const [highlightId, setHighlightId] = useState(-1)
  const { queryClient } = useApp()

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={authError} loadingComponent={authLoading}>
        
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route exact path={`${paths.inspections}/:inspectionId/:analysisId`}>
                <Layout margin={0} showProgress showHighlightId highlightId={highlightId}>
                  <Analysis updateHighlightId={(id) => setHighlightId(id)} />
                </Layout>
              </Route>
              <Route exact path={`${paths.inspections}/:inspectionId`}>
                <Layout>
                  <Analyses />
                </Layout>
              </Route>
              <Route exact path={[paths.home, paths.inspections]}>
                <Layout>
                  <Inspections />
                </Layout>
              </Route>
              <Route exact path={paths.report}>
                <Layout>
                  <Report />
                </Layout>
              </Route>
            </Switch>
          </Router>
        
        </QueryClientProvider>   
      </MsalAuthenticationTemplate>

      <UnauthenticatedTemplate>
        <Box m={1}>Not logged in. Refresh page to try again.</Box>
      </UnauthenticatedTemplate>
    </>
  )
}

export default App