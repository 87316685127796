import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  markedItem: {
    paddingRight: theme.spacing(2),
  },
}))

export default useStyles
