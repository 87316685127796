import React from 'react'
import ReactDOM from 'react-dom'
import 'src/index.css'
import App from 'src/App'
import reportWebVitals from 'src/reportWebVitals'
import { AppContextProvider } from 'src/components/AppContextProvider'
import { msalConfig } from 'src/AuthConfig'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { ThemeProvider } from '@material-ui/core'
import { theme } from 'src/theme'

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={new PublicClientApplication(msalConfig)}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AppContextProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
