import { useEffect, useRef } from "react"
import { convertRGBAToChannels } from "src/util/annotationUtil"
import useStyles from "src/components/ImageWithOverlay.style"

interface ImageWithOverlayProps {
  src: string
  overlay: ImageData
  scaleFactor: number
  selected?: boolean
  color: string
  onClick?: () => void
}

export const ImageWithOverlay = ({ src, overlay, scaleFactor, selected, color, onClick }: ImageWithOverlayProps) => {
  const classes = useStyles()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const fullWidth = overlay.width
  const fullHeight = overlay.height
  const scaledHeight = Math.floor(overlay.height/scaleFactor)
  const scaledWidth = Math.floor(overlay.width/scaleFactor)

  useEffect(() => {
    const imageDataToImage = (imageData: ImageData, onLoaded: (image: HTMLImageElement) => void) => {
      const canvas: HTMLCanvasElement = document.createElement('canvas')
      const context: CanvasRenderingContext2D | null = canvas.getContext('2d')
      canvas.width = fullWidth
      canvas.height = fullHeight
      context?.putImageData(imageData, 0, 0)
      const image = new Image()
      image.onload = () => {
        onLoaded(image)
      }
      image.src = canvas.toDataURL()
    }

    const changeColor = (imageData: ImageData): ImageData => {
      const convertedColor = convertRGBAToChannels(color)
      const data: Uint8ClampedArray = imageData.data
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i + 0]
        data[i + 0] = r === 255 ? convertedColor[0] : 0 //R
        data[i + 1] = r === 255 ? convertedColor[1] : 0 //G
        data[i + 2] = r === 255 ? convertedColor[2] : 0 //B
        data[i + 3] = r === 255 ? 100 : 0 //A
      }
      return imageData
    }

    const canvas: HTMLCanvasElement | null = canvasRef.current
    const context: CanvasRenderingContext2D | null | undefined = canvas?.getContext('2d')
    if (canvas !== null && context !== null && context !== undefined) {
      const image = new Image()
      image.onload = () => {
        context.drawImage(image, 0, 0, fullWidth, fullHeight)
        const colorImageData: ImageData = changeColor(overlay)
        const onLoaded = (imageFromData: HTMLImageElement) => {
          context.drawImage(imageFromData, 0, 0, fullWidth, fullHeight)
        }
        imageDataToImage(colorImageData, onLoaded)
      }
      image.src = src
    }
  }, [overlay, src, fullHeight, fullWidth, color])

  return (
    <canvas
      id={src}
      ref={canvasRef}
      className={selected ? classes.selected : ''}
      onClick={onClick}
      width={fullWidth}
      height={fullHeight}
      style={{ width: scaledWidth, height: scaledHeight }}
    />
  )
}