
import { Info } from 'src/components/report/Info'
import { Annotation } from 'src/components/report/Annotation'
import Select from '@material-ui/core/Select'
import { FormControl, InputLabel, MenuItem, Box, FormControlLabel, Checkbox, Card, CardContent, Button } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useState } from 'react'
import useStyles from 'src/components/report/Report.style'
import { damageCategories } from 'src/datamodel/DamageMapping'
import { Typography } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import MenuBookIcon from '@material-ui/icons/MenuBook'

export const Report = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)

  const onGenerate = (): void => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }

  return (
    <>
      <Box p={2} className={[classes.showForPrint, classes.frontPage].join(' ')}>
        <Typography variant="h4">Analysis 83</Typography>
        <br/>
        <br/>
        <Info label="Created by" value="Anders And" />
        <Info label="Created on" value="2020-02-25" />
        <Info label="Inspection" value="Pille 3 demo batch" />
        <Info label="Analysis" value="Analysis 83" />
        <Info label="Condition ratings" value="0, 1, 2" />
        <Info label="Damage categories" value={damageCategories.filter(d => d !== 'None').join(', ')} />
        <Info label="Areas" value="Pille 3" />
        <Info label="Pages included" value="1-20" />
        <Info label="Total pages" value="58" />
      </Box>
      
      <Box m={2} className={classes.hideForPrint} width={800}>
        <Card>
          <CardContent>
            <Box p={1} width={280}>
              <FormControl
                fullWidth
                margin='dense'
              >
              <InputLabel id='select'>Select analysis</InputLabel>
                <Select
                onChange={() => {}}
                  value={'Analysis 83'}
              >
                {['Analysis 83', 'Analysis 84']?.map((value, index) => {
                  return <MenuItem key={index} value={value}>{value}</MenuItem>
                })}
                </Select>
              </FormControl>
            </Box>
            <Box p={1}>
              {//TODO: How to get damages correctly?
                damageCategories.filter(d => d !== 'None').map((d, i) => 
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={true}
                      onChange={() => {}}
                      name="damage"
                      color="primary"
                    />
                  }
                  label={damageCategories.filter(d => d !== 'None')[i]}
                />
              )}
            </Box>

            <Box p={1}>
              <Typography variant="body1">
                Select pages: <b className={classes.selectedPages}>1-20</b> | 21-40 | 41-58
              </Typography>
            </Box>

            <Box p={1} mt={2}>
              <Button
                onClick={onGenerate}
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<MenuBookIcon />}
              >Generate report</Button>
              <Button
                onClick={() => window.print()}
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
              >Print</Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {!loading ?
        <>
          <Annotation
            title="Damage category: Crack with precipitation"
            subtitle="Condition rating: 2"
            mainImgSrc="/mock/report1/primary.png"
            overviewImgSrc="/mock/report1/3d.png"
            info={
              <>
                <Info label="Image timestamp" value="6:22:05" />
                <Info label="Position" value="Lat. 55.319306 Lon. 10.929986 Alt. 1.28m" />
                <Info label="Annotation created" value="2021-01-26 12:58" />
                <Info label="Tags" value="observation" />
                <Info label="Condition rating" value="2" />
                <Info label="Cause" value="Active moisture" />
                <Info label="Description" value="" />
                <Info label="Areas" value="Storebæltsforbindelsen, Vestbro" />
                <Info label="Image batch" value="pille 3" />
              </>
            }
          />
          <Annotation
            title="Damage category: Rust"
            subtitle="Condition rating: 3"
            mainImgSrc="/mock/report2/primary.png"
            overviewImgSrc="/mock/report2/3d.png"
            info={
              <>
                <Info label="Image timestamp" value="6:22:05" />
                <Info label="Position" value="Lat. 55.319306 Lon. 10.929986 Alt. 1.28m" />
                <Info label="Annotation created" value="2021-01-26 12:58" />
                <Info label="Tags" value="" />
                <Info label="Condition rating" value="3" />
                <Info label="Cause" value="Moisture" />
                <Info label="Description" value="" />
                <Info label="Areas" value="Storebæltsforbindelsen, Vestbro" />
                <Info label="Image batch" value="pille 3" />
              </>
            }
          />
          <Annotation
            title="Damage category: Spalling"
            subtitle="Condition rating: 1"
            mainImgSrc="/mock/report3/primary.png"
            overviewImgSrc="/mock/report3/3d.png"
            info={
              <>
                <Info label="Image timestamp" value="6:22:05" />
                <Info label="Position" value="Lat. 55.319306 Lon. 10.929986 Alt. 1.28m" />
                <Info label="Annotation created" value="2021-01-26 12:58" />
                <Info label="Tags" value="" />
                <Info label="Condition rating" value="1" />
                <Info label="Cause" value="Poor workmanship" />
                <Info label="Description" value="" />
                <Info label="Areas" value="Storebæltsforbindelsen, Vestbro" />
                <Info label="Image batch" value="pille 3" />
              </>
            }
          />
          <Annotation
            title="Damage category: Spalling"
            subtitle="Condition rating: 4"
            mainImgSrc="/mock/report4/primary.png"
            overviewImgSrc="/mock/report4/3d.png"
            info={
              <>
                <Info label="Image timestamp" value="6:22:05" />
                <Info label="Position" value="Lat. 55.319306 Lon. 10.929986 Alt. 1.28m" />
                <Info label="Annotation created" value="2021-01-26 12:58" />
                <Info label="Tags" value="" />
                <Info label="Condition rating" value="4" />
                <Info label="Cause" value="Stress" />
                <Info label="Description" value="" />
                <Info label="Areas" value="Storebæltsforbindelsen, Vestbro" />
                <Info label="Image batch" value="pille 3" />
              </>
            }
          />
          <Annotation
            title="Damage category: Crack"
            subtitle="Condition rating: 1"
            mainImgSrc="/mock/report5/primary.png"
            overviewImgSrc="/mock/report5/3d.png"
            info={
              <>
                <Info label="Image timestamp" value="6:22:05" />
                <Info label="Position" value="Lat. 55.319306 Lon. 10.929986 Alt. 1.28m" />
                <Info label="Annotation created" value="2021-01-26 12:58" />
                <Info label="Tags" value="observation" />
                <Info label="Condition rating" value="4" />
                <Info label="Cause" value="Thermal and plastic shrikage" />
                <Info label="Description" value="" />
                <Info label="Areas" value="Storebæltsforbindelsen, Vestbro" />
                <Info label="Image batch" value="pille 3" />
              </>
            }
          />
        </>
        :
        <Box m={2}>
          Generating report...
        </Box>
      }
    </>
  )
}