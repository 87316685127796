import { InspectionServiceClient } from 'src/datasource/InspectionServiceClient'
import { AnalysisDetails } from 'src/datasource/model/analysisDetails'
import { ServiceClient } from 'src/datasource/ServiceClient'
import { UserServiceClient } from 'src/datasource/UserServiceClient'

export class AnalysisServiceClient {
  private readonly alexandraAnalysisId: number = 230
  private readonly analysisCache: Map<number, AnalysisDetails> = new Map()

  constructor(private readonly client: ServiceClient, private readonly userServiceClient: UserServiceClient, private readonly inspectionServiceClient: InspectionServiceClient) { }

  public async getAnalysis(analysisId: number): Promise<AnalysisDetails> {
    const cachedAnalysis = this.analysisCache.get(analysisId)
    if (cachedAnalysis !== undefined) {
      return cachedAnalysis
    }
    const tenantId = await this.tenantId()
    const analysis = await this.client.getRequest('api/analysis/AnalysisDetails/tenant/' + tenantId.toString() + '/' + analysisId.toString())
    this.analysisCache.set(analysisId, analysis)
    return analysis
  }

  public async getAllAnalysis(): Promise<AnalysisDetails[]> {
    const inspectionIds = await this.inspectionServiceClient.getAllInspectionIds()

    let allAnalysis: AnalysisDetails[] = []
    for (const inspectionId of inspectionIds) {
      allAnalysis = allAnalysis.concat(await this.getAnalysisForInspection(inspectionId))
    }
    return allAnalysis;
  }

  public async getImageBatchIdFor(analysisId: number): Promise<number> {
    const analysisDetails = await this.getAnalysis(analysisId)
    return analysisDetails.imageBatchDetails.id
  }

  public async getAllAlexandraAnalysis(): Promise<AnalysisDetails[]> {
    return this.onlyAlexandraAnalysis(await this.getAllAnalysis())
  }

  public async getAnalysisForInspection(inspectionId: number): Promise<AnalysisDetails[]> {
    const tenantId = await this.tenantId()
    return await this.client.getRequest('api/analysis/AnalysisDetailsList/tenant/' + tenantId.toString() + '/' + inspectionId.toString())
  }

  public async getAlexandraAnalysisForInspection(inspectionId: number): Promise<AnalysisDetails[]> {
    return this.onlyAlexandraAnalysis(await this.getAnalysisForInspection(inspectionId))
  }

  private onlyAlexandraAnalysis(analysis: AnalysisDetails[]): AnalysisDetails[] {
    return analysis.filter(a => this.isAlexandraAnalysis(a))
  }

  public isAlexandraAnalysis(analysis: AnalysisDetails): boolean {
    return analysis.cvsProjectId === this.alexandraAnalysisId
  }

  private tenantId(): Promise<number> {
    return this.userServiceClient.getCurrentTenantId()
  }
}