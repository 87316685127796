import { useQuery } from "react-query"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Card, CardContent } from '@material-ui/core'
import { Box } from "@material-ui/core";
import { useApp } from "src/components/AppContextProvider";
import { Link } from "react-router-dom";
import useStyles from "src/components/Inspections.style";
import { paths } from "src/datamodel/Constants";
import { InspectionAnalysisReport } from "src/datasource/model/InspectionAnalysisReport";

export const Inspections = () => {
  const { account, serviceFactory } = useApp()
  const classes = useStyles()

  const inspectionsQuery = useQuery<InspectionAnalysisReport[]>('inspections', async () => await serviceFactory.getInspectionServiceClient().getAllInspections(),
  {
    enabled: account !== null,
    onError: () => {
      console.log('Error fetching inspections')
    }
  })

  return (
    <Box width={800} m={2}>
      <Card>
        <CardContent>
          {(!inspectionsQuery.isSuccess && !inspectionsQuery.isError) && inspectionsQuery.isFetching ? 
            'Loading inspections...'
            :
            <List dense component="nav" aria-label="inspections">
              {inspectionsQuery.data?.map((ins, i) => 
                <Link to={`${paths.inspections}/${ins.id}`} key={i} className={classes.link}>
                  <ListItem button >
                    <ListItemText primary={`${ins.title} (${ins.id}) [${ins.analysisCount}]`} secondary={ins.description} />
                  </ListItem>
                </Link>
              )}
            </List>
          }
          {(!inspectionsQuery.isFetching && inspectionsQuery.isError) && 
            'Could not load inspections'
          }
        </CardContent>
      </Card>
    </Box>
  )
}