/**
 * Data model "interface".
 * This is just a stub where all methods return rejected promises.
 */
export class DataModel {
  /**
   * Loader for the GLTF model.
   * @returns A promise carrying the blob data of the gltf file.
   */
  async gltfModel () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the label/consensus maps.
   * @returns A promise carrying an object with the keys alga, crac, crpr,
   * rust, spal, and position that contain the corresponding consensus maps.
   */
  async labelMaps () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the opensfm file.
   * @returns A promise carrying the JSON content of the opensfm file.
   */
  async opensfm () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the color map.
   * @returns A promise that resolves to a base64 encoded image
   * string that can be used for an <img src="...">.
   * @deprecated As of 15/06/21 this method is deprecated.
   */
  async colorMap () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the label map.
   * @returns A promise that resolves to a base64 encoded image
   * string that can be used for an <img src="...">.
   * @deprecated As of 15/06/21 this method is deprecated.
   */
  async labelMap () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the model meta data.
   * @returns The model meta data.
   * @deprecated As of 15/06/21 this method is deprecated.
   */
  async metaData () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the features (metadata).
   * @deprecated As of 15/06/21 this method is deprecated.
   */
  async features () {
    throw new Error('Not implemented')
  }

  /**
   * Loader for the 2D images that lie behind the 3D model.
   * This is needed for annotating the images to provide supervised learning
   * to the ML model.
   * @param {string} imageName The name of the image to load.
   */
  async imageById (imageName) {
    throw new Error('Not implemented')
  }
}
