import { Cause } from './Cause'
import { Damage } from './Damage'

export interface Annotation {
  id: number
  imageId: number
  imageName: string | null
  imageBatchName: string | null
  imageDateTime: string
  analysisId: number
  tagId: string | null // Should be a UUID
  probability: number | null // Should be a double
  isUserCreated: boolean
  isCluster: boolean
  damageLevel: number
  comment: string | null
  cause: string | null
  standardCauseId: number | null
  standardCause: string | null
  customCause: string | null
  created: string
  modified?: string
  probabilityPctRounded: number | null
  serviceId: string | null // Should be a UUID
  damageCategory: string
  damageCategoryId: number
  conditionRating: string | null
  conditionRatingId: number | null
  userIdCreated: number
  userCreated: string | null
  userIdModified: number
  tenantId: number
  placeId: number
  children: Annotation[]
  top: number // Should be a double
  left: number // Should be a double
  height: number // Should be a double
  width: number // Should be a double
}

export class Placement {
  public readonly top: number
  public readonly left: number
  public readonly height: number
  public readonly width: number

  //Top: Distance from top
  //Left: Distance from left
  //Height: Height of damage annotation
  //Width: Width of damage annotation
  //Should all be doubles from 0.0 to 1.0. Example: 0.74952545199193155
  constructor (top: number, left: number, height: number, width: number) {
    this.top = top
    this.left = left
    this.height = height
    this.width = width
  }
}

export class AnnotationBuilder {
  private readonly analysisId: number
  private readonly imageId: number
  private readonly damageLevel: number
  private readonly conditionRatingId: number
  private readonly userId: number
  private readonly tenantId: number
  private readonly placement: Placement
  private readonly damage: Damage
  private readonly systemId: string
  private readonly comment: string
  
  private customCause: string | null = null
  private cause: Cause | null = null

  constructor (systemId: string, analysisId: number, imageId: number, damageLevel: number, conditionRatingId: number, userId: number, tenantId: number, placement: Placement, damage: Damage, comment: string) {
    this.systemId = systemId
    this.analysisId = analysisId
    this.imageId = imageId
    this.damageLevel = damageLevel
    this.conditionRatingId = conditionRatingId
    this.userId = userId
    this.tenantId = tenantId
    this.placement = placement
    this.damage = damage
    this.comment = comment
  }

  public setCauses = (isCauseCustom: boolean, cause: Cause | undefined, customCause: string): AnnotationBuilder => {
    if (isCauseCustom && customCause !== '') {
      this.cause = null
      this.customCause = customCause
    } else {
      if (cause !== undefined) {
        this.customCause = null
        this.cause = cause
      }
    }
    return this
  }

  public finishNew (): Annotation {
    return {
      serviceId: null, // Should be a UUID
      created: new Date().toISOString(),
      id: 0,
      imageId: this.imageId,
      analysisId: this.analysisId,
      comment: this.systemId + "#" + this.comment,

      damageLevel: this.damageLevel,
      conditionRatingId: this.conditionRatingId,

      standardCauseId: this.cause === null ? null : this.cause.id,
      standardCause: this.cause === null ? null : this.cause.value,
      customCause: this.customCause, // Consider validating

      damageCategory: this.damage.value,
      damageCategoryId: this.damage.id,

      top: this.placement.top, // Should be a double
      left: this.placement.left, // Should be a double
      height: this.placement.height, // Should be a double
      width: this.placement.width, // Should be a double
      userIdCreated: this.userId,
      userIdModified: this.userId,

      cause: null,
      imageName: null,
      imageBatchName: null,
      imageDateTime: new Date().toISOString(),
      probabilityPctRounded: null,
      conditionRating: null,
      userCreated: null,
      tenantId: this.tenantId,
      placeId: 0,
      children: [],
      tagId: null, // Should be a UUID
      probability: null, // Should be a double
      isUserCreated: true,
      isCluster: false
    }
  }

  public finishUpdate (oldAnnotation: Annotation): Annotation {
    return {
      ...this.finishNew(),
      id: oldAnnotation.id,
      userIdCreated: oldAnnotation.userIdCreated,
      created: oldAnnotation.created,
      userIdModified: this.userId
    }
  }
}
